import React from 'react'

import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import { CurrencyMX } from '../../../UI/Currency'


export const WorkShops = props => {
    const { activities, confirmed, total, discount } = props

    console.log(activities);

    return (
        <Row>
            <Col>
                <div className="table-responsive-md">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activities && activities.map(activity =>
                                activity.talks.filter(item => parseFloat(item.price) > 0).map((talk, key) =>
                                    confirmed.includes(talk.id) &&
                                    <tr md={12} key={key}>
                                        <td>{talk.title}</td>
                                        <td><CurrencyMX quantity={parseFloat(talk.price)} /></td>
                                    </tr>
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="text-right">Descuento</th>
                                <th><CurrencyMX quantity={parseFloat(discount)} /></th>
                            </tr>
                            <tr>
                                <th className="text-right">Total</th>
                                <th><CurrencyMX quantity={parseFloat(total)} /></th>
                            </tr>
                            <tr className="d-print-none">
                                <td className="text-right">
                                    <Button className="waves-effect waves-light" onClick={() => window.print()} color="warning"><span className="fa fa-print"></span></Button>{" "}
                                </td>
                                <td className="text-right">
                                    <Link to="/">
                                        <Button color="success"><span className="fa fa-check"></span> Terminar</Button>
                                    </Link>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Col>
        </Row >
    )
}