import { guestConstants } from '../_constants';

export function guests(state = {}, action) {
    switch (action.type) {
        case guestConstants.GETALL_REQUEST:
            return { loading: true }
        case guestConstants.GETALL_SUCCESS:
            return action.guests
        case guestConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_guest(state = {}, action) {
    switch (action.type) {
        case guestConstants.CREATE_REQUEST:
            return { loading: true }
        case guestConstants.CREATE_SUCCESS:
            return action.guest
        case guestConstants.CREATE_FAILURE:
            return { error: action.error }

        case guestConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case guestConstants.RETRIEVE_SUCCESS:
            return action.guest
        case guestConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case guestConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}