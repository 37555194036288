import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { EventDetail } from '../../../components/Public/Event'
import { eventActions, speakerActions, speakerTypeActions } from '../../../_actions'


export class PublicEventDetail extends Component {

    componentDidMount() {
        const { getCurrentEvent, getAllSpeakers, getAllSpeakersTypes } = this.props
        const { slug } = this.props.match.params
        getCurrentEvent(slug)
        getAllSpeakers()
        getAllSpeakersTypes()
    }

    render() {
        const { event, speakers, speaker_types } = this.props
        console.log(speaker_types);
        

        return (
            <div>
                {event && event.loading &&
                    <div>Cargando...</div>
                }
                {event && event.slug &&
                    <EventDetail
                        event={event}
                        speakers={speakers && speakers.results ? speakers.results : []}
                        speaker_types={speaker_types && speaker_types.results ? speaker_types.results : []}
                    />
                }
            </div>
        )
    }
}

const mapStatetoProps = state => {
    const { alert, current_event, speakers, speaker_types } = state
    return {
        alert,
        event: current_event,
        speakers,
        speaker_types: speaker_types
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getCurrentEvent: slug => dispatch(eventActions.retrieve(slug)),
        getAllSpeakers: () => dispatch(speakerActions.getAll()),
        getAllSpeakersTypes: () => dispatch(speakerTypeActions.getAll()),
    }
}

PublicEventDetail = withRouter(connect(mapStatetoProps, mapDispatchToProps)(PublicEventDetail))
