import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Button } from 'reactstrap'
import { MDBDataTable } from 'mdbreact'
import moment from 'moment'
import 'moment-timezone'

import { DataTableEvents } from '../../components/Event/ListEvents'
import { AUX } from '../../hoc'
import { eventActions } from '../../_actions'
import { DATE_TIME_FMT, LOCAL_DATE_TIME_FMT } from '../../_helpers'


export class ListEvents extends Component {

    componentDidMount() {
        const { getAllEvents } = this.props
        getAllEvents()
    }

    handleDelete = event_id => {
        const { deleteEvent } = this.props
        deleteEvent(event_id)
    }

    render() {
        const { events, alert } = this.props

        const columns = [
            {
                label: 'Nombre',
                field: 'title',
                sort: 'asc',
            }, {
                label: 'Capacidad',
                field: 'capacity'
            }, {
                label: 'Precio',
                field: 'price'
            }, {
                label: 'Status',
                field: 'status'
            }, {
                label: 'Fecha de inicio',
                field: 'start'
            }, {
                label: 'Fecha de fin',
                field: 'end'
            }, {
                label: 'Acciones',
                field: 'actions'
            }
        ]
        return (
            <AUX className="waves-effect waves-ligh">
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {alert && typeof alert.message === 'string' &&
                                                    <div className={`alert ${alert.type} alert-dismissible fade show`} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                        {alert.message}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row m-b-20">
                                            <div className="col-12">
                                                <Link to="/admin/evento/nuevo">
                                                    <Button color="success" className="btn-sm"><i className="fa fa-plus"></i> Nuevo</Button>
                                                </Link>
                                            </div>
                                        </div>
                                        {events.loading && <em>Loading events...</em>}
                                        {events.results &&
                                            <DataTableEvents
                                                columns={columns}
                                                data={events}
                                                onDelete={this.handleDelete}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    const { alert, authentication, events } = state
    return {
        alert,
        user: authentication.user,
        events,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getAllEvents: () => dispatch(eventActions.getAll()),
        deleteEvent: id => dispatch(eventActions.destroy(id)),
    }
}

ListEvents = withRouter(connect(mapStatetoProps, mapDispatchToProps)(ListEvents))