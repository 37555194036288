import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';

class sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Tab: 'index', SubTab: '', MoreTab: ''
        };
    }

    setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
        this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
    }

    componentDidMount() {
        // var now_route = "";
        // var pageUrl = window.location.pathname.split(/[?#]/)[0];
        // now_route = pageUrl.substr(1).replace(/_/g, " ");
        // $('#now_routing').empty();
        // if (now_route === "") { now_route = "dashboard" } else { }
        // $('#now_routing').append(now_route);

        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $("body").toggleClass("enlarged");
        });

        $('li.has_sub li').on('click', function (event) {
            $("body").toggleClass("enlarged");
        });
    }
    componentDidUpdate() {
        // var now_route = "";
        // var pageUrl = window.location.pathname.split(/[?#]/)[0];
        // now_route = pageUrl.substr(1).replace("_", " ");
        // $('#now_routing').empty();
        // if (now_route === "") { now_route = "dashboard" } else { }
        // $('#now_routing').append(now_route);
    }

    render() {
        const prefix = '/admin'
        return (
            <div className="left side-menu">

                <div className="topbar-left">
                    <div className="">
                        <Link to="/" className="logo"><img src="/assets/images/logo-emkode.png" height="36" alt="logo" /></Link>
                    </div>
                </div>

                <div className="sidebar-inner slimscrollleft" >
                    <PerfectScrollbar>
                        <div id="sidebar-menu">
                            <ul>
                                <li className="menu-title">Menú</li>
                                <li>
                                    <Link to={prefix} className={this.state.Tab === 'index' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'index', '', '')}><i className="mdi mdi-view-dashboard"></i> <span>Inicio</span></Link>
                                </li>
                                <li>
                                    <Link to={`${prefix}/eventos`} className={this.state.Tab === 'events' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'events', '', '')}><i className="mdi mdi-view-dashboard"></i> <span>Eventos</span></Link>
                                </li>
                            </ul>
                        </div>

                        <div className="clearfix"></div>
                    </PerfectScrollbar>
                </div>

            </div>
        );
    }
}

export default withRouter(sidebar);