import axios from 'axios'

import Settings from '../settings.json'

export const BACKEND_URL = `${Settings.SITE_SCHEME}://${Settings.DOMAIN}`

export const API_URL = `${BACKEND_URL}${Settings.API_PATH}`



export const API = axios.create({
    baseURL: API_URL,
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' }
});

API.interceptors.request.use(function (config) {
    const user = JSON.parse(localStorage.getItem('user')),
        token = user ? user.auth_token : false

    config.headers.Authorization = token ? `Token ${token}` : '';
    return config;
})

export const PUBLIC_API = axios.create({
    baseURL: API_URL,
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' }
});
