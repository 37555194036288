import React from 'react'

import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap'
import moment from 'moment'

import { LOCAL_DATE_FMT, TIME_FMT } from '../../../../_helpers'
import { WorkShops } from './'


export const OrderDetail = props => {
    const { order: { id, status, ticket, guest, total, talks, discount }, event } = props

    return (
        <Container>
            {event.logo &&
                <div className="py-3 text-center">
                    <img className="d-block mx-auto" src={event.logo} alt={event.title} height="72" />
                </div>
            }
            <Card className="mb-4">
                <CardBody>
                    <Row>
                        <Col>
                            <h3 className="text-center text-uppercase">
                                Resumen de la compra
                            </h3>
                            <p className="text-muted text-center">
                                Orden: {id}
                                <br />
                                Estado: <span className={status === "Paid" ? "text-success" : ""}>{status}</span>
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <h3>
                                {event.title}
                            </h3>
                        </Col>
                        <Col className="text-right">
                            <p className="text-muted">
                                <span className="fa fa-calendar"></span>
                                {' '}
                                {moment(event.start).format(LOCAL_DATE_FMT)}
                                <br />
                                <span className="fa fa-clock-o"></span>
                                {' '}
                                {moment(event.start).format(TIME_FMT)}
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={2}>
                            <img src={`${ticket.document}`} alt={id} className="img-responsive img-thumbnail" height="auto" />
                        </Col>
                        <Col md={5}>
                            <h4>{guest.first_name} {guest.last_name}</h4>
                            <Row>
                                <Col md={6}>
                                    <p><i className="fa fa-envelope"></i> {guest.email}</p>
                                </Col>
                                <Col md={6}>
                                    <p><i className="fa fa-map-marker"></i> {guest.origin_place && guest.origin_place}</p>
                                </Col>
                                <Col md={6}>
                                    <p><i className="fa fa-phone"></i> {guest.phone && guest.phone}</p>
                                </Col>
                                <Col md={6}>
                                    <p><i className="fa fa-gift"></i> {guest.birthday && moment.utc(guest.birthday).format(LOCAL_DATE_FMT)}</p>
                                </Col>
                                <Col md={6}>
                                    <p><i className="fa fa-building"></i> {guest.institution && guest.institution}</p>
                                </Col>
                                <Col md={6}>
                                    <p><i className={`fa ${guest.verified === 'approved' ? 'fa-check' : 'fa-times'}`}></i> Verificado</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right d-print-none">
                                    {/*<Button color="info" size="sm">
                                        <i className="fa fa-user"></i> Actualizar mis datos (pendiente)
                                    </Button>*/}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <WorkShops
                                confirmed={talks}
                                activities={event.activities}
                                total={total}
                                discount={discount}
                                orderStatus={status}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}
