import React from 'react'

import { Row, Col } from 'reactstrap'

import { CurrencyMX } from '../../../UI/Currency'


const ShoppingCart = props => {
    const { items, total, discount, event_price } = props

    const total_with_discount = parseFloat(total) - parseFloat(discount)

    return (
        <ul className="list-group mb-3">
            {event_price &&
                <li className="list-group-item d-flex justify-content-between">
                    <span>Costo del evento</span>
                    <span className="pull-right"><CurrencyMX quantity={parseFloat(event_price)} /></span>
                </li>
            }
            {items && items.map((item, key) => {
                if (parseFloat(item.price) > 0)
                    return (
                        <li key={key} className="list-group-item justify-content-between lh-condensed">
                            <Row>
                                <Col md={8}>
                                    <h6>{item.title}</h6>
                                </Col>
                                <Col md={4}>
                                    <span className="text-muted text-right"><CurrencyMX quantity={parseFloat(item.price)} /></span>
                                </Col>
                            </Row>
                        </li>
                    )
                return ''
            })}


            <li className="list-group-item d-flex justify-content-between">
                <span>Subtotal</span>
                <span className="pull-right"><CurrencyMX quantity={parseFloat(total)} /></span>
            </li>

            {discount > 0 &&
                <li className="list-group-item d-flex justify-content-between">
                    <span>Descuento</span>
                    <span className="pull-right"><CurrencyMX quantity={parseFloat(discount)} /></span>
                </li>
            }
            <li className="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong className="pull-right"><CurrencyMX quantity={total_with_discount >= 0 ? total_with_discount : 0} /></strong>
            </li>
        </ul>
    )
}

export default ShoppingCart
