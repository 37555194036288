export const speakerConstants = {
    GETALL_REQUEST: 'SPEAKERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'SPEAKERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'SPEAKERS_GETALL_FAILURE',

    CREATE_REQUEST: 'SPEAKER_CREATE_REQUEST',
    CREATE_SUCCESS: 'SPEAKER_CREATE_SUCCESS',
    CREATE_FAILURE: 'SPEAKER_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'SPEAKER_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'SPEAKER_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'SPEAKER_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'SPEAKER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SPEAKER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SPEAKER_UPDATE_FAILURE',

    CURRENT_CLEAR: 'SPEAKER_CURRENT_CLEAR',

}
