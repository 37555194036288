import React, { Component } from 'react'

import moment from 'moment'

import { talkService, eventService } from '../../_services'
import { Register } from '../../components/Event/Register'
import { guestService } from '../../_services'
import { AUX } from '../../hoc'
import { DATE_FMT } from '../../_helpers'
import { orderService } from '../../_services'
import { history } from '../../_helpers'


export class RegisterEvent extends Component {

    state = {
        event: {},
        workshops: [],
        workshops_purchased: [],
        errors: [],
        submitted: false,
        guest: {},
        total: 0.00,
        paypal: {
            paid: false
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params
        eventService.retrieve(id)
            .then(event => {
                if (event && event.id) {
                    this.setState({ event })
                    talkService.getAllTalksPayment(event.id, 'payment')
                        .then(workshops => {
                            if (workshops && workshops.results) {
                                // event['workshops'] = workshops
                                this.setState({ workshops: workshops.results })
                            }
                        })
                }
            })
    }

    handleOnChange = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.value
        this.setState({ guest })
    }

    handleOnChangeFile = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.files[0]
        this.setState({ guest })
    }

    handleOnChangeWorkShop = e => {
        let { workshops, workshops_purchased, total } = this.state
        let indexTalk = workshops_purchased.findIndex(item => item.id === e.target.name)

        const talk_datail = workshops.find(item => item.id === e.target.name)

        if (indexTalk > -1) {
            workshops_purchased.splice(indexTalk, 1)
            total -= parseFloat(talk_datail.price)
        } else {
            workshops_purchased.push(talk_datail)
            total += parseFloat(talk_datail.price)
        }
        this.setState({ workshops_purchased, total })
    }

    handleOnChangeBirthday = date => {
        let { guest } = this.state
        let birthday = null

        if (date) {
            birthday = moment.utc(date).format(DATE_FMT)
        }

        guest['birthday'] = birthday
        this.setState({ guest })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({ submitted: true })

        let { event, guest, workshops_purchased } = this.state
        const { first_name, last_name, email, guest_type } = guest

        if (first_name && last_name && email && guest_type) {
            guest['event'] = event.id
            guest['workshops'] = workshops_purchased.map(workshop => workshop.id)

            let formData = new FormData()
            for (var key in guest) {
                formData.append(key, guest[key])
            }

            guestService.create(formData)
                .then(response => {
                    if (response && response.id) {
                        history.push(`/ticket/${response.id}`)
                    } else if (response && response.hasOwnProperty('errors')) {
                        this.setState({ errors: response.errors })
                    }
                })
        }
    }

    render() {
        const { event, errors, workshops, workshops_purchased, total, submitted, guest } = this.state

        return (
            <Register
                event={event}
                errors={errors}
                workshops={workshops}
                workshops_purchased={workshops_purchased}
                total={total}
                submitted={submitted}
                guest={guest}
                onSubmit={this.handleSubmit}
                onChange={this.handleOnChange}
                onChangeBirthday={this.handleOnChangeBirthday}
                onChangeWorkShop={this.handleOnChangeWorkShop}
                onChangeFile={this.handleOnChangeFile}
            />
        )
    }
}
