export const eventConstants = {
    GETALL_REQUEST: 'EVENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'EVENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'EVENTS_GETALL_FAILURE',

    CREATE_REQUEST: 'EVENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'EVENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'EVENT_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'EVENT_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'EVENT_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'EVENT_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'EVENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'EVENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'EVENT_UPDATE_FAILURE',

    CURRENT_CLEAR: 'EVENT_CURRENT_CLEAR',

}
