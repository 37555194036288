import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ReactMomentCountDown from 'react-moment-countdown';
import moment from 'moment';

import { AUX } from '../../hoc'

export class ComingSoon extends Component {

    componentDidMount() {
        if (this.props.loginpage === false) {
            this.props.UpdateLogin();
        }
        window.onpopstate = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    render() {

        const dateInFuture = moment('2019-09-15 18:00:00', 'YYYY-MM-DD HH:mm:ss');
        console.log(dateInFuture)
        return (
            <AUX>
                <section className="mt-5 mb-5">
                    <div className="container-alt container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="home-wrapper m-t-40">
                                    <Link to="/admin"><img src="/assets/images/emkode.png" alt="logo" height="auto" /></Link>
                                    <h3 className="m-t-30">Coming Soon</h3>
                                    <p>We are working hard to launch a new website with new features. </p>

                                    <div className="coming-watch text-center mb-5">
                                        <div className="countdown">
                                            <div className="lj-countdown-ms testtimes">
                                                <ReactMomentCountDown toDate={dateInFuture} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AUX>
        );
    }
}
