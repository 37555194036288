import { orderConstants } from '../_constants';

export function orders(state = {}, action) {
    switch (action.type) {
        case orderConstants.GETALL_REQUEST:
            return { loading: true }
        case orderConstants.GETALL_SUCCESS:
            return action.orders
        case orderConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_order(state = {}, action) {
    switch (action.type) {
        case orderConstants.CREATE_REQUEST:
            return { loading: true }
        case orderConstants.CREATE_SUCCESS:
            return action.order
        case orderConstants.CREATE_FAILURE:
            return { error: action.error }

        case orderConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case orderConstants.RETRIEVE_SUCCESS:
            return action.order
        case orderConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case orderConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}