import React from 'react'

import { Link } from 'react-router-dom'

import { Container, Card, CardBody, CardHeader, Row, Col, CardFooter, Button } from 'reactstrap'
import moment from 'moment'
import 'moment-timezone'

import { DATE_TIME_FMT, LOCAL_DATE_TIME_FMT } from '../../../_helpers'


export const PublishedEventDetail = ({ event: { id, logo, image, document, title, start, end, location, description, capacity, price } }) => {
    let startUTC = moment.utc(start).format(DATE_TIME_FMT),
        startLocal = moment.utc(startUTC).toDate(),
        endUTC = moment.utc(end).format(DATE_TIME_FMT),
        endLocal = moment.utc(endUTC).toDate()
    startLocal = moment(startLocal).format(LOCAL_DATE_TIME_FMT);
    endLocal = moment(endLocal).format(LOCAL_DATE_TIME_FMT);
    return (
        <Container>
            
        </Container>
    )
}
