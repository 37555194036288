import React, { Component } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset } from 'redux-form'
import { Row, Col, Label, Card, CardHeader, CardBody, CardText, CardTitle } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'

import { AUX } from '../../hoc'
import { speakerActions } from '../../_actions'
import { eventService, talkService } from '../../_services'
import NewEventFormWizard from '../../components/Event/NewEventFormWizard'

import { ModalFormHeaderFooter } from '../../components/UI/Modal'
import { RenderField } from '../../components/UI/Input'

export class NewEvent extends Component {

    state = {
        submitted: false,
        speakerFormSubmitted: false,
        speakers: [],
        newSpeaker: {},
        modalNewSpeaker: false,
        speakerWasCreated: false,
        eventCreated: {
            success: false,
            errors: [],
            data: {}
        },
        talksCreated: []
    }

    componentDidMount() {
        const { getAllSpeakers } = this.props
        getAllSpeakers()
    }

    componentWillReceiveProps(nextProps, prevState) {
        const { current_speaker, getAllSpeakers } = nextProps
        const { speakerWasCreated } = this.state

        if (current_speaker && current_speaker.id && !speakerWasCreated) {
            this.setState({ speakerWasCreated: true, modalNewSpeaker: false })
            getAllSpeakers()
        }

    }

    toggleModalSpeaker = () => this.setState(prevState => ({ modalNewSpeaker: !prevState.modalNewSpeaker }))

    handlerSubmitSpeaker = e => {
        e.preventDefault()
        this.setState({ speakerFormSubmitted: true })
        const { title, first_name, last_name, email } = this.state.newSpeaker
        const { createSpeaker } = this.props

        if (title && first_name && last_name && email) {
            createSpeaker(this.state.newSpeaker)
        }
    }

    handlerOnChangeSpeakerField = input => {
        let { newSpeaker } = this.state
        newSpeaker[input.target.name] = input.target.value
        this.setState({ newSpeaker })
    }

    handlerSubmit = data => {
        this.setState({ submitted: true })

        const { title, description, price, capacity, status, start, end, talks } = data
        const { user } = this.props

        const event = {
            title,
            description,
            price,
            capacity,
            status,
            start,
            end,
            user: user.id,
            talks
        }

        eventService.create(event)
            .then(newEvent => {
                if (newEvent && newEvent.id) {
                    const { eventCreated } = this.state
                    eventCreated['data'] = newEvent
                    eventCreated['success'] = true
                    this.setState({ eventCreated })
                    // talks.map((talk, key) => {
                    //     talk['event'] = newEvent.id
                    //     talk['speaker'] = !talk.hasOwnProperty('speaker') ? null : talk.speaker
                    //     talkService.create(talk)
                    //         .then(newTalk => {
                    //             if (newTalk && newTalk.id) {
                    //                 const talkCreated = {
                    //                     data: newTalk,
                    //                     success: true,
                    //                     errors: []
                    //                 }
                    //                 let { talksCreated } = this.state
                    //                 talksCreated.push(talkCreated)
                    //                 this.setState(talksCreated)
                    //             } else if (newTalk && newTalk.error) {
                    //                 console.error(newTalk.errors)
                    //             } else {
                    //                 console.error(newTalk)
                    //             }
                    //         })
                    // })
                } else if (newEvent && newEvent.errors) {
                    console.error(newEvent.errors)
                } else {
                    console.error(newEvent)
                }
            })
    }


    render() {
        const { submitted, eventCreated, talksCreated } = this.state
        const { speakers, alert, current_speaker } = this.props

        return (
            <AUX className="waves-effect waves-light">
                {!submitted &&
                    <NewEventFormWizard
                        onSubmit={this.handlerSubmit}
                        toggleModalSpeaker={this.toggleModalSpeaker}
                        speakers={speakers}
                    />
                }

                {submitted &&
                    <div className="page-content-wrapper">
                        <div className="container-fluid">
                            <Card>
                                <CardHeader>
                                    <h4>Creando evento</h4>
                                </CardHeader>
                                <CardBody>
                                    <CardText>{eventCreated.data.title} <span className={eventCreated.success ? "text-success fa fa-check" : "text-danger fa fa-times"}></span></CardText>
                                    <h5>Ponencias</h5>
                                    <ul>
                                        {talksCreated.map((talk, key) =>
                                            <li key={key}><CardText key={key}>{talk.data.title} <span className={talk.success ? "text-success fa fa-check" : "text-danger fa fa-times"}></span></CardText></li>
                                        )}
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                }

                <ModalFormHeaderFooter
                    modalTarget="add-new-speaker"
                    title="Nuevo ponente"
                    onSubmit={this.handlerSubmitSpeaker}
                    show={this.state.modalNewSpeaker}
                    toggle={this.toggleModalSpeaker}
                    submitted={this.state.speakerFormSubmitted}
                >
                    <Row>
                        <Col sm="4">
                            <Label>Título <span className="text-danger">*</span></Label>
                            <select name="title" defaultValue="" className="form-control" onChange={this.handlerOnChangeSpeakerField} required>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="mr">Sr</option>
                                <option value="ms">Sra</option>
                                <option value="ing">Ing</option>
                                <option value="dr">Dr</option>
                                <option value="dra">Dra</option>
                                <option value="lic">Lic</option>
                            </select>

                        </Col>
                        <Col sm="4">
                            <RenderField
                                name="first_name"
                                type="text"
                                value={this.state.newSpeaker.first_name}
                                required
                                label="Nombre(s)"
                                onChange={this.handlerOnChangeSpeakerField}
                            />
                        </Col>
                        <Col sm="4">
                            <RenderField
                                name="last_name"
                                type="text"
                                value={this.state.newSpeaker.last_name}
                                required
                                label="Apellidos"
                                onChange={this.handlerOnChangeSpeakerField}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <RenderField
                                name="email"
                                type="email"
                                value={this.state.newSpeaker.email}
                                required
                                label="Correo"
                                onChange={this.handlerOnChangeSpeakerField}
                                error={alert && alert.type === 'alert-danger' && alert.message.find(error => error.field === "email")}
                            />
                        </Col>
                        <Col sm="4">
                            <RenderField
                                name="phone"
                                type="text"
                                value={this.state.newSpeaker.phone}
                                label="Telefono"
                                onChange={this.handlerOnChangeSpeakerField}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <RenderField
                                name="bio"
                                type="textarea"
                                value={this.state.newSpeaker.bio}
                                label="Biografía"
                                onChange={this.handlerOnChangeSpeakerField}
                            />
                        </Col>
                    </Row>
                </ModalFormHeaderFooter>

                {this.state.speakerWasCreated ?
                    <SweetAlert
                        title={alert.hasOwnProperty('message') ? alert.message : 'cargando'}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => this.setState({ speakerWasCreated: false })}
                    >
                        {`${current_speaker.title} ${current_speaker.first_name} ${current_speaker.last_name}`}
                    </SweetAlert> : null}
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    const { alert, authentication, speakers, current_speaker } = state
    return {
        alert,
        user: authentication.user,
        speakers,
        current_speaker
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        createSpeaker: data => dispatch(speakerActions.create(data)),
        getAllSpeakers: () => dispatch(speakerActions.getAll()),
    }
}

NewEvent = withRouter(connect(mapStatetoProps, mapDispatchToProps)(NewEvent))