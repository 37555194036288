import React from 'react'

import { Row, Col, Container, Card, CardBody, Button, Badge } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { LOCAL_DATE_TIME_FMT } from '../../../../_helpers'
import { AUX } from '../../../../hoc'
import { CurrencyMX } from '../../../UI/Currency'
import Activity from './Activity'


export const EventDetail = props => {
    const { event: { title, slug, logo, banner, description, start, end, price, activities }, speakers, speaker_types } = props
    const event_is_finished = moment(end) < moment(new Date())

    return (
        <AUX>
            <Container>
                <Row>
                    <Col>
                        <Link to={`/`}><img className="d-block mx-auto m-4" src={logo ? logo : '/assets/images/emkode.png'} height="72" alt={title} /></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h2 className="text-center">{title} {event_is_finished && <Badge color='warning'>Evento finalizado</Badge>}</h2>
                                    </Col>
                                </Row>
                                {banner &&
                                    <Row className="m-4">
                                        <Col>
                                            <img className="img-responsive img-thumbnail" width="100%" src={banner} alt={title} />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="p-4">
                                        <Row>
                                            <Col xs="12" md={6}>
                                                {start && <p><span className="fa fa-calendar"></span> {moment(start).format(LOCAL_DATE_TIME_FMT)}</p>}
                                            </Col>
                                            <Col xs="12" md={6}>

                                                {end && <p><span className="fa fa-calendar"></span> {moment(end).format(LOCAL_DATE_TIME_FMT)}</p>}
                                            </Col>
                                        </Row>
                                    </Col>
                                        <Col className="p-4">
                                            <Row>
                                                <Col>
                                                    {!event_is_finished &&
                                                        <Link to={`/evento/${slug}/registro`}><Button size="md" color="info" block>Registrarse</Button></Link>
                                                    }
                                                    {event_is_finished &&
                                                        <Link to={`/evento/${slug}/constancia`}><Button size="md" color="success" block>Ver constancia</Button></Link>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <h4>Actividades</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {activities && activities.length &&
                                                activities.map((activity, key) =>
                                                    <Col md={12} key={key}>
                                                        <Activity {...activity} speakers={speakers} speaker_types={speaker_types} />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                {!event_is_finished &&
                                    <Row>
                                        <Col md={{ size: 6, offset: 3 }} >
                                            <Link to={`/evento/${slug}/registro`}><Button size="lg" color="info" block>Registrarse</Button></Link>
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <footer className="my-5 pt-5 text-muted text-center text-small">
                <p className="mb-1">&copy; 2018-2021 Kodeference</p>
                <ul className="list-inline">
                    <li className="list-inline-item">Powered by <a href="https://emkode.com" target="_blank" rel="noopener noreferrer">Emkode</a></li>
                </ul>
            </footer>
        </AUX>
    )
}
