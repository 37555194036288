import React from 'react'

import { Label, Row, Col, Input } from 'reactstrap'
import moment from 'moment'

import { CurrencyMX } from '../../../UI/Currency'
import Speaker from '../Detail/Speaker'
import { LOCAL_DATE_TIME_FMT } from '../../../../_helpers'


const Talks = props => {
    const { talks, onChangeTalk, speakers, speaker_types } = props

    return (
        <div>
            {talks && talks.map((talk, key) => {
                const isDisabled = parseInt(talk.capacity) > 0 && parseInt(talk.capacity) <= parseInt(talk.registered)
                console.log(isDisabled);

                return (
                    <div key={key}>
                        <hr />
                        <Row>
                            <Col>
                                {isDisabled &&
                                    <Row>
                                        <Col>
                                            <h5 className="text-right text-danger"> <Label>¡Cupo lleno, no quedan más lugares!</Label></h5>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md={7} sm={12}>
                                        <Label>
                                            {talk.title}
                                            <br />
                                        </Label>
                                        {/* {speakers && speakers.map((speaker, key) => <Speaker key={key} {...speaker} speaker_types={speaker_types} />)} */}
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <CurrencyMX quantity={parseFloat(talk.price)} />
                                    </Col>
                                    <Col md={2} sm={6}>
                                        <Input
                                            name={talk.id}
                                            type="checkbox"
                                            id={`talk${talk.id}`}
                                            switch="bool"
                                            defaultChecked={parseFloat(talk.price) === 0 && ((parseInt(talk.capacity) > 600 && parseInt(talk.capacity) > parseInt(talk.registered)) || parseInt(talk.capacity) === 0) ? "on" : ""}
                                            onChange={onChangeTalk}
                                            disabled={isDisabled}
                                        />
                                        <Label htmlFor={`talk${talk.id}`} data-on-label="Si" data-off-label="No"></Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            <i className="text-warning fa fa-calendar"></i>
                                            {' '}
                                            Inicio
                                        <br />
                                            {talk.start && <span>{moment(talk.start).format(LOCAL_DATE_TIME_FMT)}</span>}
                                        </p>
                                    </Col>
                                    <Col>
                                        <p>
                                            <i className="text-success fa fa-clock-o"></i>
                                            {' '}
                                            Duración
                                        <br />
                                            {talk.duration && <span>{moment.utc(moment.duration(talk.duration, "minutes").asMilliseconds()).format("HH:mm")}</span>}
                                            {' '}
                                            horas
                                    </p>
                                    </Col>
                                    <Col>
                                        <p>
                                            <i className="text-success fa fa-users"></i>
                                            {' '}
                                            Capacidad
                                        <br />
                                            <span><strong>{talk.capacity > 0 ? <span>{talk.registered}/{talk.capacity}</span> : 'ilimitada'}</strong></span>
                                        </p>
                                    </Col>
                                </Row>
                                {talk.observations.length > 0 &&
                                    <Row>
                                        <Col>
                                            <Label>Observaciones:</Label>
                                            <div dangerouslySetInnerHTML={{ __html: talk.observations }}></div>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            })}
        </div>
    )
}

export default Talks
