import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from '../views/Dashboard/Dashboard'
import { ListEvents, NewEvent, EditEvent } from '../views/Events'


class mainbuilder extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/admin" render={props => <Dashboard {...props} />} />
                <Route exact path="/admin/eventos" component={props => <ListEvents {...props} />} />
                <Route exact path="/admin/evento/nuevo" render={props => <NewEvent {...props} />} />
                <Route exact path="/admin/evento/:id" render={props => <EditEvent {...props} />} />
            </Switch>
        )
    }
}

export default mainbuilder