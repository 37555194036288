import React, { Component } from 'react';
import { AUX } from '../../hoc'

import NewEventFirstStep from './SubPages/NewEventFirstStep'
import NewEventSecondStep from './SubPages/NewEventSecondStep'
import NewEventThirdStep from './SubPages/NewEventThirdStep'


class NewEventFormWizard extends Component {

    state = {
        page: 1
    }

    nextPage = () => this.setState({ page: this.state.page + 1 })
    previousPage = () => this.setState({ page: this.state.page - 1 })

    render() {
        const { onSubmit, toggleModalSpeaker } = this.props;
        const { page } = this.state;

        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <div>
                                            {page === 1 && <NewEventFirstStep onSubmit={this.nextPage} />}
                                            {page === 2 &&
                                                <NewEventSecondStep
                                                    previousPage={this.previousPage}
                                                    onSubmit={this.nextPage}
                                                    toggleModalSpeaker={toggleModalSpeaker}
                                                />
                                            }
                                            {page === 3 && <NewEventThirdStep previousPage={this.previousPage} onSubmit={onSubmit} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default NewEventFormWizard;
