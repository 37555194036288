import React from 'react'

import { Row, Col, Card, CardBody } from 'reactstrap'
import moment from 'moment'

import { CurrencyMX } from '../../../UI/Currency'
import { LOCAL_DATE_TIME_FMT } from '../../../../_helpers'
import Talk from './Talk'



const Activity = props => {
    const { address, description, image, name, price, start, end, capacity, registered, talks, speakers, speaker_types } = props

    return (
        <Card className="mt-4">
            <CardBody>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <h5 className="text-center">{name}</h5>
                            </Col>
                        </Row>
                        {image &&
                            <Row className="text-center">
                                <Col>
                                    <img src={image} className="img-thumbnail" height="auto" width="80%" alt={name} />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p><i className="text-info fa fa-home"></i> Dirección</p>
                                <div dangerouslySetInnerHTML={{ __html: address }}></div>
                            </Col>
                            {/* <Col className="text-center">
                                <p><i className="text-success fa fa-money"></i> Costo</p>
                                <div>{parseFloat(price) === 0 ? 'Actividad gratuita' : <CurrencyMX quantity={parseFloat(price)} />}</div>
                            </Col>
                            <Col className="text-center">
                                <p><i className="text-info fa fa-users"></i> Capacidad</p>
                                <div>{parseFloat(price) === 0 ? 'Ilimitada' : capacity}</div>
                            </Col> */}
                            <Col className="text-center">
                                <p><i className="text-warning fa fa-calendar"></i> Fechas</p>
                                {start && <p>{moment(start).format(LOCAL_DATE_TIME_FMT)}</p>}
                                {end && <p>{moment(end).format(LOCAL_DATE_TIME_FMT)}</p>}
                            </Col>
                        </Row>
                        {talks && talks.length &&
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p><strong>Ponencias</strong></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {talks.map((talk, key) => {
                                            let currentSpeakers = speakers.filter(speaker => talk.speakers.includes(speaker.id))
                                            return (
                                                <Col md={6} key={key}>
                                                    <Talk {...talk} speakers={currentSpeakers} speaker_types={speaker_types} />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Activity
