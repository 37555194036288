import { speakerConstants } from '../_constants'
import { speakerService } from '../_services'
import { alertActions } from './'

export const speakerActions = {
    getAll,
    create,
    retrieve,
    update,
    destroy,
    clear
}

function getAll() {
    return dispatch => {
        dispatch(request())

        speakerService.getAll()
            .then(
                speakers => {
                    if (speakers) {
                        if (speakers.hasOwnProperty('results')) {
                            dispatch(success(speakers))
                        } else if (speakers.hasOwnProperty('errors')) {
                            dispatch(failure(speakers.errors))
                        } else {
                            dispatch(failure([{ message: speakers.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerConstants.GETALL_REQUEST } }
    function success(speakers) { return { type: speakerConstants.GETALL_SUCCESS, speakers } }
    function failure(error) { return { type: speakerConstants.GETALL_FAILURE, error } }
}

function create(data) {
    return dispatch => {
        dispatch(request())

        speakerService.create(data)
            .then(
                speaker => {
                    if (speaker && speaker.id) {
                        console.log(speaker)
                        dispatch(success(speaker))
                        dispatch(alertActions.success("¡Ponente creado correctamente!"))
                    } else if (speaker && speaker.errors) {
                        console.error(speaker)
                        dispatch(failure(speaker.errors))
                        dispatch(alertActions.error(speaker.errors))
                    } else {
                        dispatch(failure([{ message: speaker.message }]))
                        dispatch(alertActions.error([{ message: speaker.message }]))
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerConstants.CREATE_REQUEST } }
    function success(speaker) { return { type: speakerConstants.CREATE_SUCCESS, speaker } }
    function failure(error) { return { type: speakerConstants.CREATE_FAILURE, error } }
}

function retrieve(id) {
    return dispatch => {
        dispatch(request())

        speakerService.retrieve(id)
            .then(
                speaker => {
                    if (speaker) {
                        if (speaker.hasOwnProperty('id')) {
                            dispatch(success(speaker))
                        } else if (speaker.hasOwnProperty('errors')) {
                            dispatch(failure(speaker.errors))
                        } else {
                            dispatch(failure([{ message: speaker.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerConstants.RETRIEVE_REQUEST } }
    function success(speaker) { return { type: speakerConstants.RETRIEVE_SUCCESS, speaker } }
    function failure(error) { return { type: speakerConstants.RETRIEVE_FAILURE, error } }
}

function update(id, data) {
    return dispatch => {
        dispatch(request())

        speakerService.update(id, data)
            .then(
                speaker => {
                    if (speaker) {
                        if (speaker.hasOwnProperty('id')) {
                            dispatch(success(speaker))
                            dispatch(alertActions.updated("¡speakero actualizado correctamente!"))
                        } else if (speaker.hasOwnProperty('errors')) {
                            dispatch(failure(speaker.errors))
                            dispatch(alertActions.error(speaker.errors))
                        } else {
                            dispatch(failure([{ message: speaker.message }]))
                            dispatch(alertActions.error([{ message: speaker.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerConstants.UPDATE_REQUEST } }
    function success(speaker) { return { type: speakerConstants.UPDATE_SUCCESS, speaker } }
    function failure(error) { return { type: speakerConstants.UPDATE_FAILURE, error } }
}

function destroy(id) {
    return dispatch => {
        dispatch(request())

        const data = { status: 'deleted' }

        speakerService.update(id, data)
            .then(
                speaker => {
                    if (speaker) {
                        if (speaker.hasOwnProperty('id')) {
                            dispatch(success(speaker))
                            dispatch(alertActions.success("¡speakero eliminado correctamente!"))
                        } else if (speaker.hasOwnProperty('errors')) {
                            dispatch(failure(speaker.errors))
                            dispatch(alertActions.error(speaker.errors))
                        } else {
                            dispatch(failure([{ message: speaker.message }]))
                            dispatch(alertActions.error([{ message: speaker.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerConstants.UPDATE_REQUEST } }
    function success(speaker) { return { type: speakerConstants.UPDATE_SUCCESS, speaker } }
    function failure(error) { return { type: speakerConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: speakerConstants.CURRENT_CLEAR }
}
