import React from 'react'

import { Label, Row, Col } from 'reactstrap'

import { CurrencyMX } from '../../../components/UI/Currency'


export const WorkShops = props => {
    const { items, onChange } = props
    return (
        <ul className="list-group mb-3">
            {items &&
                items.map((item, key) =>
                    <Row key={key}>
                        <Col md={8}>
                            <p>{item.title} | <strong><CurrencyMX quantity={parseFloat(item.price)} /></strong></p>

                        </Col>
                        <Col>
                            <input name={item.id} type="checkbox" id={`workshop${key}`} switch="bool" onChange={onChange} />
                            <Label htmlFor={`workshop${key}`} data-on-label="Si" data-off-label="No"></Label>
                        </Col>
                    </Row>
                )}
        </ul>
    )
}
