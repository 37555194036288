import React from 'react'

import { Label, Row, Col, Card, CardTitle, CardBody } from 'reactstrap'

import { CurrencyMX } from '../../../UI/Currency'
import Talks from './Talks'
import { AUX } from '../../../../hoc/AUX'


const Activities = props => {
    const { activities, onChangeTalk, speakers, speaker_types } = props

    return (
        <AUX>
            {/* <Row>
                <Col>
                    <h5>Ponencias con costo:</h5>
                </Col>
            </Row>
            <Row>
                {activities && activities.map((activity, key) => {
                    const workshops = activity.talks.filter(item => parseFloat(item.price) > 0)
                    return (
                        <Col md={6} key={key}>
                            <Card>
                                <CardBody>
                                    <h5>{activity.name}</h5>
                                    <p>
                                        <i className="text-info fa fa-home"></i> Dirección
                                        <span dangerouslySetInnerHTML={{ __html: activity.address }}></span>
                                    </p>
                                    <Row>
                                        <Col>
                                            <Talks key={key} talks={workshops} onChangeTalk={onChangeTalk} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <hr /> */}
            {/* <Row>
                <Col>
                    <h5>Ponencias gratuitas:</h5>
                </Col>
            </Row> */}
            <hr />
            <Row>
                <Col>
                    <h4 className="text-danger">Selecciona las actividades a las que deseas asistir:</h4>
                </Col>
            </Row>
            <Row>
                {activities && activities.map((activity, key) => {
                    const talks = activity.talks.filter(item => parseFloat(item.price) === 0)
                    return (
                        <Col md={6} key={key} className="mt-4">
                            <Card>
                                <CardBody>
                                    <h5>{activity.name}</h5>
                                    <p>
                                        <i className="text-info fa fa-home"></i> Dirección
                                        <span dangerouslySetInnerHTML={{ __html: activity.address }}></span>
                                    </p>
                                    <Row>
                                        <Col>
                                            <Talks key={key} talks={talks} onChangeTalk={onChangeTalk} speakers={speakers} speaker_types={speaker_types} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </AUX>
    )
}

export default Activities
