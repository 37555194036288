import React from 'react'

import { Link, Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { MDBDataTable } from 'mdbreact'
import moment from 'moment'
import 'moment-timezone'

import { DATE_TIME_FMT, LOCAL_DATE_TIME_FMT } from '../../../_helpers'
import { BACKEND_URL } from '../../../_utils/API'


export const DataTableCertificate = ({ data, columns, onSubmitMail }) => {
    return data.length>0 ?
        <MDBDataTable
            striped
            bordered
            responsive
            columns={columns}
            searching={false}
            paging={false}
            rows={data.map(guest => {
                let first_name = guest.first_name
                let last_name = guest.last_name
                let url = guest.pdf
                return {
                    name: first_name,
                    last_name: last_name,
                    actions:
                        <div>
                        <a href={url} target={"_blank"}>
                            <Button
                                className="btn-sm"
                                color="info"
                            >
                                <span className="fa fa-download"></span>
                            </Button>
                        </a>
                        &nbsp;

                            <Button
                                className="btn-sm"
                                color="info"
                                onClick={() => onSubmitMail(first_name, last_name, guest.email, url)}>
                                <span className="fa fa-envelope"></span>
                            </Button>
                        </div>
                }
            })}
        />
        : <div>No se encontraron resultados</div>


}
