import { speakerTypeConstants } from '../_constants'
import { speakerTypeService } from '../_services'
import { alertActions } from '.'

export const speakerTypeActions = {
    getAll,
    getAllPublished,
    create,
    retrieve,
    update,
    destroy,
    clear
}

function getAll() {
    return dispatch => {
        dispatch(request())

        speakerTypeService.getAll()
            .then(
                speakerTypes => {
                    if (speakerTypes) {
                        if (speakerTypes.hasOwnProperty('results')) {
                            dispatch(success(speakerTypes))
                        } else if (speakerTypes.hasOwnProperty('errors')) {
                            dispatch(failure(speakerTypes.errors))
                        } else {
                            dispatch(failure([{ message: speakerTypes.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.GETALL_REQUEST } }
    function success(speakerTypes) { return { type: speakerTypeConstants.GETALL_SUCCESS, speakerTypes } }
    function failure(error) { return { type: speakerTypeConstants.GETALL_FAILURE, error } }
}

function getAllPublished() {
    return dispatch => {
        dispatch(request())

        speakerTypeService.getAllPublished()
            .then(
                speakerTypes => {
                    if (speakerTypes) {
                        if (speakerTypes.hasOwnProperty('results')) {
                            dispatch(success(speakerTypes))
                        } else if (speakerTypes.hasOwnProperty('errors')) {
                            dispatch(failure(speakerTypes.errors))
                        } else {
                            dispatch(failure([{ message: speakerTypes.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.GETALL_REQUEST } }
    function success(speakerTypes) { return { type: speakerTypeConstants.GETALL_SUCCESS, speakerTypes } }
    function failure(error) { return { type: speakerTypeConstants.GETALL_FAILURE, error } }
}

function create(data) {
    return dispatch => {
        dispatch(request())

        speakerTypeService.create(data)
            .then(
                speakerType => {
                    if (speakerType && speakerType.id) {
                        dispatch(success(speakerType))
                    } else if (speakerType && speakerType.errors) {
                        dispatch(failure(speakerType.errors))
                        dispatch(alertActions.error(speakerType.errors))
                    } else {
                        dispatch(failure([{ message: speakerType.message }]))
                        dispatch(alertActions.error([{ message: speakerType.message }]))
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.CREATE_REQUEST } }
    function success(speakerType) { return { type: speakerTypeConstants.CREATE_SUCCESS, speakerType } }
    function failure(error) { return { type: speakerTypeConstants.CREATE_FAILURE, error } }
}

function retrieve(slug) {
    return dispatch => {
        dispatch(request())

        speakerTypeService.retrieve(slug)
            .then(
                speakerType => {
                    if (speakerType) {                        
                        if (speakerType.hasOwnProperty('slug')) {
                            dispatch(success(speakerType))
                        } else if (speakerType.hasOwnProperty('errors')) {
                            dispatch(failure(speakerType.errors))
                        } else {
                            dispatch(failure([{ message: speakerType.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.RETRIEVE_REQUEST } }
    function success(speakerType) { return { type: speakerTypeConstants.RETRIEVE_SUCCESS, speakerType } }
    function failure(error) { return { type: speakerTypeConstants.RETRIEVE_FAILURE, error } }
}

function update(id, data) {
    return dispatch => {
        dispatch(request())

        speakerTypeService.update(id, data)
            .then(
                speakerType => {
                    if (speakerType) {
                        if (speakerType.hasOwnProperty('id')) {
                            dispatch(success(speakerType))
                            dispatch(alertActions.updated("¡speakerTypeo actualizado correctamente!"))
                        } else if (speakerType.hasOwnProperty('errors')) {
                            dispatch(failure(speakerType.errors))
                            dispatch(alertActions.error(speakerType.errors))
                        } else {
                            dispatch(failure([{ message: speakerType.message }]))
                            dispatch(alertActions.error([{ message: speakerType.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.UPDATE_REQUEST } }
    function success(speakerType) { return { type: speakerTypeConstants.UPDATE_SUCCESS, speakerType } }
    function failure(error) { return { type: speakerTypeConstants.UPDATE_FAILURE, error } }
}

function destroy(id) {
    return dispatch => {
        dispatch(request())

        const data = { status: 'deleted' }

        speakerTypeService.update(id, data)
            .then(
                speakerType => {
                    if (speakerType) {
                        if (speakerType.hasOwnProperty('id')) {
                            dispatch(success(speakerType))
                            dispatch(alertActions.success("¡speakerTypeo eliminado correctamente!"))
                        } else if (speakerType.hasOwnProperty('errors')) {
                            dispatch(failure(speakerType.errors))
                            dispatch(alertActions.error(speakerType.errors))
                        } else {
                            dispatch(failure([{ message: speakerType.message }]))
                            dispatch(alertActions.error([{ message: speakerType.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: speakerTypeConstants.UPDATE_REQUEST } }
    function success(speakerType) { return { type: speakerTypeConstants.UPDATE_SUCCESS, speakerType } }
    function failure(error) { return { type: speakerTypeConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: speakerTypeConstants.CURRENT_CLEAR }
}
