import { orderConstants } from '../_constants'
import { orderService } from '../_services'
import { alertActions } from './'
import { history } from '../_helpers'

export const orderActions = {
    getAll,
    create,
    retrieve,
    update,
    destroy,
    clear
}

function getAll() {
    return dispatch => {
        dispatch(request())

        orderService.getAll()
            .then(
                orders => {
                    if (orders) {
                        if (orders.hasOwnProperty('results')) {
                            dispatch(success(orders))
                        } else if (orders.hasOwnProperty('errors')) {
                            dispatch(failure(orders.errors))
                        } else {
                            dispatch(failure([{ message: orders.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: orderConstants.GETALL_REQUEST } }
    function success(orders) { return { type: orderConstants.GETALL_SUCCESS, orders } }
    function failure(error) { return { type: orderConstants.GETALL_FAILURE, error } }
}

function create(data) {
    return dispatch => {
        dispatch(request())

        orderService.create(data)
            .then(
                order => {
                    if (order && order.id) {
                        dispatch(success(order))
                        history.push(`/orden/${order.id}`)
                    } else if (order && order.errors) {
                        dispatch(failure(order.errors))
                        dispatch(alertActions.error(order.errors))
                    } else {
                        dispatch(failure([{ message: order.message }]))
                        dispatch(alertActions.error([{ message: order.message }]))
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: orderConstants.CREATE_REQUEST } }
    function success(order) { return { type: orderConstants.CREATE_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.CREATE_FAILURE, error } }
}

function retrieve(id) {
    return dispatch => {
        dispatch(request())

        orderService.retrieve(id)
            .then(
                order => {
                    if (order) {
                        if (order.hasOwnProperty('id')) {
                            dispatch(success(order))
                        } else if (order.hasOwnProperty('errors')) {
                            dispatch(failure(order.errors))
                        } else {
                            dispatch(failure([{ message: order.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: orderConstants.RETRIEVE_REQUEST } }
    function success(order) { return { type: orderConstants.RETRIEVE_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.RETRIEVE_FAILURE, error } }
}

function update(id, data) {
    return dispatch => {
        dispatch(request())

        orderService.update(id, data)
            .then(
                order => {
                    if (order) {
                        if (order.hasOwnProperty('id')) {
                            dispatch(success(order))
                        } else if (order.hasOwnProperty('errors')) {
                            dispatch(failure(order.errors))
                            dispatch(alertActions.error(order.errors))
                        } else {
                            dispatch(failure([{ message: order.message }]))
                            dispatch(alertActions.error([{ message: order.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: orderConstants.UPDATE_REQUEST } }
    function success(order) { return { type: orderConstants.UPDATE_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.UPDATE_FAILURE, error } }
}

function destroy(id) {
    return dispatch => {
        dispatch(request())

        const data = { status: 'deleted' }

        orderService.update(id, data)
            .then(
                order => {
                    if (order) {
                        if (order.hasOwnProperty('id')) {
                            dispatch(success(order))
                        } else if (order.hasOwnProperty('errors')) {
                            dispatch(failure(order.errors))
                            dispatch(alertActions.error(order.errors))
                        } else {
                            dispatch(failure([{ message: order.message }]))
                            dispatch(alertActions.error([{ message: order.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: orderConstants.UPDATE_REQUEST } }
    function success(order) { return { type: orderConstants.UPDATE_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: orderConstants.CURRENT_CLEAR }
}
