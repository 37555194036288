import React from 'react'

import { Container, Row, Col, Card, CardBody } from 'reactstrap'

import { Form, ShoppingCart } from './'


export const Register = props => {
    const { event, errors, workshops, workshops_purchased, total, submitted, guest, onSubmit, onChange, onChangeBirthday, onChangeWorkShop, onChangeFile } = props
    return (
        <Container>
            <div className="py-3 text-center">
                <img className="d-block mx-auto" src="/assets/images/harr/harr.png" alt="" height="72" />
            </div>
            {event &&
                <Card className="mb-4">
                    <CardBody>
                        <h3>Regístrate: {event.title}</h3>
                        <Row>
                            <Col md={12}>
                                {errors && errors.find(item => item.field === "event") &&
                                    <div className="alert alert-danger fade show" role="alert">
                                        {errors.find(item => item.field === "event").message}
                                    </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="order-md-2 mb-4">
                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-muted">Carrito</span>
                                </h4>
                                <ShoppingCart items={workshops_purchased} total={total} />
                            </Col>
                            <Col md={8} className="order-md-1">
                                <Form
                                    onSubmit={onSubmit}
                                    submitted={submitted}
                                    data={guest}
                                    onChange={onChange}
                                    onChangeBirthDay={onChangeBirthday}
                                    onChangeWorkShop={onChangeWorkShop}
                                    workshops={workshops}
                                    errors={errors}
                                    onChangeFile={onChangeFile}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </Container>
    )
}
