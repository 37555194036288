import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'


export const ModalFormHeaderFooter = props => (
    <div>
        <Modal size="lg" isOpen={props.show} toggle={props.toggle} className={props.className}>
            <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
            <form
                noValidate
                className={"form-horizontal needs-validation" + (props.submitted ? " was-validated" : "")}
            >
                <ModalBody>
                    {props.children}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={props.toggle}>Cancelar</Button>
                    <Button color="success" type="submit" onClick={props.onSubmit}>Guardar</Button>
                </ModalFooter>
            </form>
        </Modal>
    </div>
)
