import React, { Component } from 'react';
import { FieldArray, reduxForm } from 'redux-form';

import validate from './validate';
import RenderTalk from './RenderTalk'


class NewEventSecondStep extends Component {

    state = {
        submitted: false,
    }

    handleSubmitValidation = e => {
        e.preventDefault()
        this.setState({ submitted: true })
        this.props.handleSubmit(e)
    }

    render() {
        const { submitted } = this.state
        const { previousPage, toggleModalSpeaker } = this.props
        return (
            <form onSubmit={this.handleSubmitValidation} className={"form-horizontal needs-validation" + (submitted ? " was-validated" : "")} noValidate>
                <h3>Ponencias</h3>
                <hr />
                <FieldArray
                    name="talks"
                    component={RenderTalk}
                    toggleModalSpeaker={toggleModalSpeaker}
                />

                <hr />

                <div id="btn_div">
                    <button type="button" className="btn btn-secondary previous" onClick={previousPage}>Anterior</button>
                    &nbsp;
                    <button type="submit" className="btn btn-primary next">Siguiente</button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'newEvent', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(NewEventSecondStep)