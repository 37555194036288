import { speakerConstants } from '../_constants';

export function speakers(state = {}, action) {
    switch (action.type) {
        case speakerConstants.GETALL_REQUEST:
            return { loading: true }
        case speakerConstants.GETALL_SUCCESS:
            return action.speakers
        case speakerConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_speaker(state = {}, action) {
    switch (action.type) {
        case speakerConstants.CREATE_REQUEST:
            return { loading: true }
        case speakerConstants.CREATE_SUCCESS:
            return action.speaker
        case speakerConstants.CREATE_FAILURE:
            return { error: action.error }

        case speakerConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case speakerConstants.RETRIEVE_SUCCESS:
            return action.speaker
        case speakerConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case speakerConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}