import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Router, Route, Switch, withRouter } from 'react-router-dom'

import { history } from './_helpers'
import { alertActions } from './_actions'

import Layout from './components/Layout/Layout'
import Login from './views/Auth/Login'
import { ComingSoon } from './views/Pages'
import { PublicEventList, PublicEventDetail, PublicEventRegister, Constancia} from './views/Public/Events'
import { PublicOrderDetail } from './views/Public/Orders'
import { PrivateRoute } from './components/PrivateRoute'
import mainbuilder from './router/mainbuilder'


class App extends Component {

    constructor(props) {
        super(props)

        const { dispatch } = this.props
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear())
        })
    }

    render() {
        let layout = null

        layout = (
            <Layout header={this.props.header} sidebar={this.props.sidebar} footer={this.props.footer} loginpage={this.props.loginpage}>
                <PrivateRoute component={mainbuilder} />
            </Layout>
        )

        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" render={props => <PublicEventList {...props} />} />
                    <Route exact path="/evento/:slug" render={props => <PublicEventDetail {...props} />} />
                    <Route exact path="/evento/:slug/registro" render={props => <PublicEventRegister {...props} />} />
                    <Route exact path="/evento/:slug/constancia" render={props => <Constancia {...props} />} />
                    <Route exact path="/orden/:id" render={props => <PublicOrderDetail {...props} />} />
                    <Route exact path="/login" render={props => <Login {...props} />} />
                    {layout}
                </Switch>
            </Router>
        )
    }
}

const mapStatetoProps = state => {
    const { alert } = state
    return {
        header: state.ui_red.header,
        sidebar: state.ui_red.sidebar,
        footer: state.ui_red.footer,
        loginpage: state.ui_red.loginpage,
        alert
    }
}

export default withRouter(connect(mapStatetoProps)(App))
