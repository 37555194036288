import { eventConstants } from '../_constants';

export function events(state = {}, action) {
    switch (action.type) {
        case eventConstants.GETALL_REQUEST:
            return { loading: true }
        case eventConstants.GETALL_SUCCESS:
            return action.events
        case eventConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_event(state = {}, action) {
    switch (action.type) {
        case eventConstants.CREATE_REQUEST:
            return { loading: true }
        case eventConstants.CREATE_SUCCESS:
            return action.event
        case eventConstants.CREATE_FAILURE:
            return { error: action.error }

        case eventConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case eventConstants.RETRIEVE_SUCCESS:
            return action.event
        case eventConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case eventConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}