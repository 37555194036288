import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import RenderField from './RenderField'

import { DateTimePicker } from '../../UI/DateTimePicker'


class NewEventFirstStep extends Component {

    state = {
        submitted: false
    }

    handleDefault = date => this.setState({ default_date: date })
    handleAutoClose = date => this.setState({ auto_close: date })

    handleStart = date => {
        const { event } = this.state
        event['start'] = date
        this.setState({ event })
    }

    handleEnd = date => {
        const { event } = this.state
        event['end'] = date
        this.setState({ event })
    }

    handleSubmitValidation = e => {
        e.preventDefault()
        this.setState({ submitted: true })
        this.props.handleSubmit(e)
    }

    render() {
        const { submitted } = this.state

        return (
            <form
                onSubmit={this.handleSubmitValidation}
                className={"form-horizontal needs-validation" + (submitted ? " was-validated" : "")}
                noValidate
            >
                <h3>Detalles del Evento</h3>
                <hr />
                <fieldset>
                    <div className="row">
                        <div className="col-md-9">
                            <Field
                                name="title"
                                type="text"
                                component={RenderField}
                                required
                                label="Título"
                            />
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Status</label>
                                <Field
                                    name="status"
                                    component="select"
                                    className="form-control"
                                >
                                    <option value="draft">Borrador</option>
                                    <option value="published">Publicado</option>
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="capacity"
                                        type="number"
                                        component={RenderField}
                                        step="1"
                                        min="0"
                                        label="Capacidad"
                                        helpText="Capacidad de personas, 0 para ilimitado"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="price"
                                        type="number"
                                        component={RenderField}
                                        className="form-control"
                                        min="0"
                                        label="Precio"
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field name="start" component={DateTimePicker} required label="Inicia" />
                                </div>
                                <div className="col-md-6">
                                    <Field name="end" component={DateTimePicker} required label="Termina" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Field
                                name="location"
                                type="textarea"
                                component={RenderField}
                                label="Dirección"
                                rows={5}
                                placeholder="Dirección completa del lugar"
                                style={{ paddingBottom: "18px" }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Field
                                name="description"
                                type="textarea"
                                component={RenderField}
                                label="Descripción"
                            />
                        </div>
                    </div>
                </fieldset>
                <hr />

                <div id="btn_div">
                    <button type="submit" className="btn btn-secondary" disabled>Anterior</button>
                    &nbsp;
                    <button type="submit" className="btn btn-primary next">Siguiente</button>
                </div>
            </form >
        )
    }
}

export default NewEventFirstStep = reduxForm({
    form: 'newEvent',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(NewEventFirstStep)
