import React from 'react'

import { Row, Col } from 'reactstrap'

import { CurrencyMX } from '../../../components/UI/Currency'


export const ShoppingCart = props => {
    const { items, total } = props

    return (
        <ul className="list-group mb-3">
            {items && items.map((item, key) =>
                <li key={key} className="list-group-item justify-content-between lh-condensed">
                    <Row>
                        <Col md={9}>
                            <h6>
                                {item.title}
                                <small className="text-muted">{item.speaker}</small>
                            </h6>
                        </Col>
                        <Col md={3}>
                            <span className="text-muted pull-right"><CurrencyMX quantity={parseFloat(item.price)} /></span>
                        </Col>
                    </Row>
                </li>
            )}

            <li className="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong className="pull-right"><CurrencyMX quantity={parseFloat(total)} /></strong>
            </li>
        </ul>
    )
}