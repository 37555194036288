import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import 'moment-timezone'
import { Button, Row, Col, Card, CardHeader, CardBody, Badge } from 'reactstrap'

import { SpeakerDetails } from '../../Speaker'
import { DATE_TIME_FMT, LOCAL_DATE_TIME_FMT } from '../../../_helpers'

import validate from './validate'


class NewEventSecondStep extends Component {

    render() {
        const { handleSubmit, pristine, previousPage, submitting, newEvent } = this.props

        let startEventUTC = moment.utc(newEvent.start).format(DATE_TIME_FMT),
            endEventUTC = moment.utc(newEvent.end).format(DATE_TIME_FMT),
            startEventLocal = moment.utc(startEventUTC).toDate(),
            endEventLocal = moment.utc(endEventUTC).toDate()
        startEventLocal = moment(startEventLocal).format(LOCAL_DATE_TIME_FMT)
        endEventLocal = moment(endEventLocal).format(LOCAL_DATE_TIME_FMT)      

        return (
            <Row>
                <Col md={12}>
                    <form onSubmit={handleSubmit}>
                        <h3>Este es el resumen del evento que se creará</h3>
                        <hr />
                        <Row>
                            <Col>
                                <h4>Título: {newEvent.title}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Status: <strong>{newEvent.status}</strong></p>
                            </Col>
                            <Col>
                                <p>Capacidad: <strong>{newEvent.capacity}</strong></p>
                            </Col>
                            <Col>
                                <p>Precio: <strong>{newEvent.price}</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Dirección: <strong>{newEvent.location}</strong></p>
                            </Col>
                            <Col>
                                <p>Fecha de inicio: <strong>{startEventLocal}</strong></p>
                            </Col>
                            <Col>
                                <p>Fecha de fin: <strong>{endEventLocal}</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Descripción:</label>
                                <div>{newEvent.description}</div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h5>Ponencias</h5>
                            </Col>
                        </Row>
                        <Row>
                            {newEvent.talks && newEvent.talks.map((talk, key) => {
                                let startTalkUTC = moment.utc(talk.start).format(DATE_TIME_FMT),
                                    startTalkLocal = moment.utc(startTalkUTC).toDate()
                                    startTalkLocal = moment(startTalkLocal).format(LOCAL_DATE_TIME_FMT)
                                return (
                                    <Col md={6} key={key}>
                                        <Card className="m-b-20">
                                            <CardHeader><strong>{talk.title}</strong><span className="pull-right"><Badge color="teal">{talk.status}</Badge></span></CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <p>Tipo: <strong>{talk.talk_type}</strong></p>
                                                    </Col>
                                                    <Col>
                                                        <p>Capacidad: <strong>{talk.capacity}</strong></p>
                                                    </Col>
                                                    <Col>
                                                        <p>Precio: <strong>{talk.capacity}</strong></p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p>Inicia: <strong>{startTalkLocal}</strong></p>
                                                    </Col>
                                                    <Col>
                                                        <p>Duración: <strong>{talk.duration}</strong></p>
                                                    </Col>
                                                </Row>
                                                {talk.speaker && <SpeakerDetails id={talk.speaker} />}
                                                <Row>
                                                    <Col>
                                                        <p>Resumen:</p>
                                                        <p>{talk.abstract}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p>Observaciones:</p>
                                                        <p>{talk.observations}</p>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                            )}
                        </Row>
                        <hr />
                        <Row className="pull-right">
                            <Col>
                                <Button type="button" className="btn btn-secondary previous" onClick={previousPage}>Anterior</Button>{" "}
                                <Button type="submit" className="btn btn-success next" disabled={pristine || submitting} >Guardar</Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        )
    }
}

NewEventSecondStep = reduxForm({
    form: 'newEvent', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(NewEventSecondStep)

const selector = formValueSelector('newEvent'); // <-- same as form name

NewEventSecondStep = connect(state => {
    // can select values individually
    const title = selector(state, 'title');
    const description = selector(state, 'description');
    const capacity = selector(state, 'capacity');
    const price = selector(state, 'price');
    const start = selector(state, 'start');
    const end = selector(state, 'end');
    const status = selector(state, 'status');
    const talks = selector(state, 'talks');

    return {
        newEvent: {
            title,
            description,
            capacity,
            price,
            start,
            end,
            status,
            talks
        }
    }
})(NewEventSecondStep);

export default NewEventSecondStep;