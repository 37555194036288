import React from 'react'

import { Container, Row, Col, Card, CardBody, Button, Badge } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

import ShoppingCart from './ShoppingCart'
import Form from './Form'
import Activities from './Activities'


export const RegisterEvent = props => {

    const { event, errors, purchased_workshops, total, discount, onSubmit, submitted, validate, guest, onChange, onChangeBirthday, onChangeTalk, onChangeFile, onChangeGuestType, speakers, speaker_types} = props
    const { activities, end, slug } = event
    const event_is_finished = moment(end) < moment(new Date())

    return (
        <Container>
            <div className="py-3 text-center">
                <Link to={`/evento/${slug}`}><img className="d-block mx-auto" src={event.logo} alt={event.title} height="72" /></Link>
            </div>
            {event && event_is_finished &&
                <Card className="mb-4">
                    <CardBody>
                        <h2>{event.title} <Badge color='warning'>Evento finalizado</Badge></h2>
                        <Row>
                            <Col>
                                <Link to='/'><Button size="md" color="info" block>Ver todos los eventos</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {event && !event_is_finished &&
                <Card className="mb-4">
                    <CardBody>
                        <h3>Regístrate: {event.title}</h3>
                        <Row>
                            <Col md={12}>
                                {errors && errors.find(item => item.field === "event") &&
                                    <div className="alert alert-danger fade show" role="alert">
                                        {errors.find(item => item.field === "event").message}
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {errors && errors.map((error, key) => error.field === 'non_field_errors' &&
                                    <div key={key} className="alert alert-danger fade show" role="alert">
                                        {error.message}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <Form
                                    submitted={submitted}
                                    data={guest}
                                    onChange={onChange}
                                    onChangeBirthDay={onChangeBirthday}
                                    errors={errors}
                                    onChangeFile={onChangeFile}
                                    onChangeGuestType={onChangeGuestType}
                                />
                            </Col>
                            <Col md={5}>
                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-muted">Carrito</span>
                                </h4>
                                <ShoppingCart
                                    items={purchased_workshops}
                                    event_price={event.price}
                                    total={total}
                                    discount={discount}
                                />
                            </Col>
                        </Row>
                        <Activities activities={activities} onChangeTalk={onChangeTalk} speaker_types={speaker_types} speakers={speakers} />
                        <hr className="mb-4" />
                        <Button size="lg" color="success" block disabled={validate && errors.length === 0} type="button" onClick={onSubmit}>
                            {validate && errors.length === 0 ?
                                <span>
                                    Guardando...{' '}<i className="fa fa-spin fa-spinner"></i>
                                </span> :
                                <span>
                                    Continuar{' '}<i className="fa fa-arrow-circle-right"></i>
                                </span>
                            }
                        </Button>
                    </CardBody>
                </Card>
            }
        </Container>
    )
}
