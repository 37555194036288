import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { AUX } from '../../hoc'
import { userActions } from '../../_actions'


class Login extends Component {

    constructor(props) {
        super(props)
        this.props.dispatch(userActions.logout());
    }

    state = {
        email: "daniel@emkode.com",
        password: "EmkodexD",
        submitted: false
    }

    handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleSubmit = e => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { email, password } = this.state
        const { dispatch } = this.props
        if (email && password) {
            dispatch(userActions.login(email, password))
        }
    }

    render() {
        const { email, password, submitted } = this.state
        const { alert } = this.props

        return (
            <AUX>
                <div className="accountbg"></div>
                <div className="wrapper-page">
                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center">
                                <Link to="/" className="logo logo-admin"><img src="/assets/images/emkode.png" height="100" alt="logo" /></Link>
                            </h3>

                            <div>

                                {alert && alert.type &&
                                    <div className={`alert ${alert.type}`} role="alert">
                                        {alert.message.map((item, key) => <li key>{item.message}</li>)}
                                    </div>
                                }

                                <form className={"form-horizontal m-t-30 needs-validation" + (submitted ? " was-validated" : "")} onSubmit={this.handleSubmit} noValidate>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input value={email} onChange={this.handleChange} type="text" className="form-control" name="email" id="email" placeholder="Enter Email" required />
                                        <div className="invalid-feedback">El email es requerido.</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input value={password} onChange={this.handleChange} type="password" className="form-control" name="password" id="password" placeholder="Enter password" required />
                                        <div className="invalid-feedback">El password es requerido.</div>
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-sm-6">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                <label className="custom-control-label" htmlFor="customControlInline">Recordarme</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Entrar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        )
    }
}

const mapStatetoProps = state => {
    const { alert } = state
    const { loggingIn } = state.authentication
    return {
        loggingIn,
        alert
    }
}

export default connect(mapStatetoProps)(Login)