import React from 'react'

import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { MDBDataTable } from 'mdbreact'
import moment from 'moment'
import 'moment-timezone'

import { DATE_TIME_FMT, LOCAL_DATE_TIME_FMT } from '../../../_helpers'


export const DataTableEvents = ({ data, columns, onDelete }) => {
    return data && data.hasOwnProperty('results') ?
        <MDBDataTable
            striped
            bordered
            small
            responsive
            columns={columns}
            rows={data.results.map(event => {
                let startUTC = moment.utc(event.start).format(DATE_TIME_FMT),
                    startLocal = moment.utc(startUTC).toDate(),
                    endUTC = moment.utc(event.end).format(DATE_TIME_FMT),
                    endLocal = moment.utc(endUTC).toDate()
                startLocal = moment(startLocal).format(LOCAL_DATE_TIME_FMT);
                endLocal = moment(endLocal).format(LOCAL_DATE_TIME_FMT);

                return {
                    title: event.title,
                    capacity: event.capacity,
                    price: event.price,
                    status: event.status,
                    start: startLocal,
                    end: endLocal,
                    actions:
                        <div>
                            <Link to={`evento/${event.id}`}>
                                <Button
                                    className="btn-sm"
                                    color="info"
                                >
                                    <span className="fa fa-pencil"></span>
                                </Button>
                            </Link>
                            {" "}
                            <Button
                                type="button"
                                color="danger"
                                className="btn-sm"
                                onClick={() => onDelete(event.id)}>
                                <span className="fa fa-trash"></span>
                            </Button>
                        </div>
                }
            })}
        />
        : <div></div>


}