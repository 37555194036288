import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { ListEvents } from '../../../components/Public/Event'
import { eventActions } from '../../../_actions'


export class PublicEventList extends Component {

    componentDidMount() {
        const { getAllEvents } = this.props
        getAllEvents()
    }

    render() {

        const { events } = this.props

        return (
            <div>
                {events && events.loading &&
                    <div>Cargando...</div>
                }
                {events && events.results &&
                    <ListEvents events={events.results} />
                }
            </div>
        )
    }
}

const mapStatetoProps = state => {
    const { alert, events } = state
    return {
        alert,
        events,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getAllEvents: () => dispatch(eventActions.getAllPublished()),
    }
}

PublicEventList = withRouter(connect(mapStatetoProps, mapDispatchToProps)(PublicEventList))
