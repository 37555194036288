import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { eventActions } from '../../_actions'

import { AUX } from '../../hoc'
import { PublishedEventsList } from '../../components/Event/ListEvents'


export class ListPublishedEvents extends Component {

    componentDidMount() {
        const { getAllEvents } = this.props
        getAllEvents()
    }

    render() {
        const { events } = this.props
        console.log(events)
        return (
            <AUX>
                {events && events.loading && <span>Cargando...</span>}
                {events && events.results && <PublishedEventsList events={events} />}
            </AUX>
        )
    }
}

const mapStatetoProps = state => {
    const { alert, events } = state
    return {
        alert,
        events
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getAllEvents: () => dispatch(eventActions.getAllPublished()),
    }
}

ListPublishedEvents = withRouter(connect(mapStatetoProps, mapDispatchToProps)(ListPublishedEvents))