import React from 'react'

import { Container, Row, Col } from 'reactstrap'

import { PublishedEventDetail } from '../Detail'


export const PublishedEventsList = ({ events }) =>
    <Container>
        <Row>
            <Col>
                <h2 className="text-center">Próximos eventos</h2>
            </Col>
        </Row>
        <Row>
            {events.results.map((event, key) =>
                <Col md={6} key={key}>
                    <PublishedEventDetail event={event} />
                </Col>
            )}
        </Row>
    </Container>