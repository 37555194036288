export const guestConstants = {
    GETALL_REQUEST: 'GUESTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'GUESTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'GUESTS_GETALL_FAILURE',

    CREATE_REQUEST: 'GUEST_CREATE_REQUEST',
    CREATE_SUCCESS: 'GUEST_CREATE_SUCCESS',
    CREATE_FAILURE: 'GUEST_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'GUEST_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'GUEST_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'GUEST_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'GUEST_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GUEST_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GUEST_UPDATE_FAILURE',

    CURRENT_CLEAR: 'GUEST_CURRENT_CLEAR',

}
