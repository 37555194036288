import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { orderActions, eventActions } from '../../../_actions'
import { OrderDetail } from '../../../components/Public/Order/'


export class PublicOrderDetail extends Component {

    componentDidMount() {
        const { getCurrentOrder } = this.props
        const { id } = this.props.match.params
        getCurrentOrder(id)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.event === this.props.event) {
            const { guest } = nextProps.order
            if (guest && guest.event) {
                const { getCurrentEvent } = this.props
                getCurrentEvent(guest.event)
            }
        }
    }


    render() {
        const { order, event } = this.props

        return (
            <div>
                {order && order.loading &&
                    <p>Cargando...</p>
                }
                {order && order.id && event &&
                    <OrderDetail
                        order={order}
                        event={event}
                    />
                }
            </div>
        )
    }


}

const mapStatetoProps = state => {
    const { alert, current_order, current_event } = state
    return {
        alert,
        event: current_event,
        order: current_order
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getCurrentOrder: id => dispatch(orderActions.retrieve(id)),
        getCurrentEvent: slug => dispatch(eventActions.retrieve(slug)),
    }
}

PublicOrderDetail = withRouter(connect(mapStatetoProps, mapDispatchToProps)(PublicOrderDetail))