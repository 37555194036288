import { speakerTypeConstants } from '../_constants';

export function speaker_types(state = {}, action) {
    switch (action.type) {
        case speakerTypeConstants.GETALL_REQUEST:
            return { loading: true }
        case speakerTypeConstants.GETALL_SUCCESS:
            return action.speakerTypes
        case speakerTypeConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_speaker_type(state = {}, action) {
    switch (action.type) {
        case speakerTypeConstants.CREATE_REQUEST:
            return { loading: true }
        case speakerTypeConstants.CREATE_SUCCESS:
            return action.speakerType
        case speakerTypeConstants.CREATE_FAILURE:
            return { error: action.error }

        case speakerTypeConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case speakerTypeConstants.RETRIEVE_SUCCESS:
            return action.speakerType
        case speakerTypeConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case speakerTypeConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}