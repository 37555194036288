import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'

import { DATE_FMT } from '../../../_helpers'
import { eventActions, orderActions, speakerActions, speakerTypeActions } from '../../../_actions'
import { RegisterEvent } from '../../../components/Public/Event'


export class PublicEventRegister extends Component {

    state = {
        total: 0,
        discount: 0,
        purchased_workshops: [],
        guest: {},
        errors: [],
        submitted: false,
        validate: false,
    }

    componentDidMount() {
        const { getCurrentEvent, getAllSpeakers, getAllSpeakersTypes } = this.props
        const { slug } = this.props.match.params
        getCurrentEvent(slug)
        getAllSpeakers()
        getAllSpeakersTypes()
    }

    componentWillReceiveProps(nextProps) {
        const { event, alert } = nextProps

        if (alert && alert.hasOwnProperty('message')) {
            console.log(alert)
            window.scrollTo(0, 0)
        }


        if (event && event.activities) {
            const purchased_workshops = []

            for (const activity of event.activities) {
                for (const talk of activity.talks) {
                    const capacity = parseInt(talk.capacity)
                    const price = parseFloat(talk.price)
                    const registered = parseFloat(talk.registered)
                    if (price === 0 && ((capacity > 100 && capacity > registered) || capacity === 0)) {
                        purchased_workshops.push(talk)
                    }
                }
            }
            this.setState({ total: parseFloat(event.price), purchased_workshops })
        }
    }

    handleOnChange = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.value
        this.setState({ guest })
    }

    handleOnChangeGuestType = e => {
        let { guest, discount } = this.state
        const guest_type = e.target.value
        guest[e.target.name] = guest_type

        if (guest_type === 'student') {
            const { event } = this.props
            discount = event.price
        } else {
            discount = 0
            delete guest['document']
        }

        this.setState({ guest, discount })
    }

    handleOnChangeFile = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.files[0]
        this.setState({ guest })
    }

    handleOnChangeTalk = e => {
        const { event } = this.props
        let { purchased_workshops, total } = this.state
        let indexTalk = purchased_workshops.findIndex(item => item.id === e.target.name)

        const talks = []
        event.activities.map(activity => activity.talks.map(talk => talks.push(talk)))
        const talk_detail = talks.find(talk => talk.id === e.target.name)


        if (indexTalk > -1) {
            purchased_workshops.splice(indexTalk, 1)
            total -= parseFloat(talk_detail.price)
        } else {
            purchased_workshops.push(talk_detail)
            total += parseFloat(talk_detail.price)
        }
        this.setState({ purchased_workshops, total })
    }

    handleOnChangeBirthday = date => {
        let { guest } = this.state
        guest['birthday'] = date ? date : null
        this.setState({ guest })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({ submitted: true })

        const { purchased_workshops, guest, discount } = this.state
        const { createOrder, event } = this.props
        const { first_name, last_name, email, guest_type, document, birthday, phone } = guest

        if (first_name && last_name && email && guest_type && phone) {
            if ((guest_type === 'student') || guest_type !== 'student') {
                this.setState({ validate: true })
                let formData = new FormData()
                guest['event'] = event.slug
                guest['discount'] = discount
                for (var key in guest) {
                    formData.append(`guest.${key}`, guest[key])
                }
                formData.set('guest.birthday', moment(birthday).format(DATE_FMT))
                purchased_workshops.map(item => formData.append('talks', item.id))
                createOrder(formData)
            }
        }
    }

    render() {
        const { purchased_workshops, total, submitted, guest, discount, validate } = this.state
        const { event, alert, speakers, speaker_types } = this.props

        return (
            <div>
                {event && event.loading &&
                    <div>Cargando...</div>
                }
                {event && event.slug &&
                    <RegisterEvent
                        event={event}
                        purchased_workshops={purchased_workshops}
                        total={total}
                        discount={discount}
                        errors={alert && alert.message ? alert.message : []}
                        submitted={submitted}
                        validate={validate}
                        guest={guest}
                        onSubmit={this.handleSubmit}
                        onChange={this.handleOnChange}
                        onChangeBirthday={this.handleOnChangeBirthday}
                        onChangeTalk={this.handleOnChangeTalk}
                        onChangeFile={this.handleOnChangeFile}
                        onChangeGuestType={this.handleOnChangeGuestType}
                        speakers={speakers.results}
                        speaker_types={speaker_types && speaker_types.results ? speaker_types.results : []}
                    />
                }
            </div>
        )
    }
}

const mapStatetoProps = state => {
    const { alert, current_event, speakers, speaker_types } = state
    return {
        alert,
        event: current_event,
        speakers,
        speaker_types
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getCurrentEvent: slug => dispatch(eventActions.retrieve(slug)),
        createOrder: payload => dispatch(orderActions.create(payload)),
        getAllSpeakers: () => dispatch(speakerActions.getAll()),
        getAllSpeakersTypes: () => dispatch(speakerTypeActions.getAll())
    }
}

PublicEventRegister = withRouter(connect(mapStatetoProps, mapDispatchToProps)(PublicEventRegister))
