import { talkConstants } from '../_constants';

export function talks(state = {}, action) {
    switch (action.type) {
        case talkConstants.GETALL_REQUEST:
            return { loading: true }
        case talkConstants.GETALL_SUCCESS:
            return action.talks
        case talkConstants.GETALL_FAILURE:
            return { error: action.error }
        default:
            return state
    }
}

export function current_talk(state = {}, action) {
    switch (action.type) {
        case talkConstants.CREATE_REQUEST:
            return { loading: true }
        case talkConstants.CREATE_SUCCESS:
            return action.talk
        case talkConstants.CREATE_FAILURE:
            return { error: action.error }

        case talkConstants.RETRIEVE_REQUEST:
            return { loading: true }
        case talkConstants.RETRIEVE_SUCCESS:
            return action.talk
        case talkConstants.RETRIEVE_FAILURE:
            return { error: action.error }
        case talkConstants.CURRENT_CLEAR:
            return {}
        default:
            return state
    }
}