import React from 'react'

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import es from 'date-fns/locale/es'

import { DATE_FMT } from '../../../_helpers'

registerLocale('es', es)


export const DateTimePicker = ({ input: { onChange, value, name }, label, helpText, required, showTimeSelect = true }) =>
    <div className="form-group">
        <label>{label}{required && <span className="text-danger"> *</span>}</label>
        <DatePicker
            locale="es"
            className="form-control"
            selected={value ? new Date(value) : null}
            onChange={onChange}
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            timeCaption="Hora"
            dateFormat="dd/MMM/yyyy h:mm aa"
            required={required}
            name={name}
        />
        {helpText && <small className="form-text text-muted">{helpText}</small>}
    </div>


export const DatePickerBirthday = ({ onChange, value, name, label, helpText, required = false, error }) => {

    return (
        <div className="form-group">
            <label>{label}{required && <span className="text-danger"> *</span>}</label>
            <DatePicker
                locale="es"
                selected={value ? new Date(value) : null}
                className="form-control"
                onChange={onChange}
                dateFormat="dd/MMMM/yyyy"
                required={required}
                name={name}
                showYearDropdown
                yearDropdownItemNumber={40}
                scrollableYearDropdown
                maxDate={moment().toDate()}
            />
            {error && <small className="text-danger">{error.message}</small>}
            {helpText && <small className="form-text text-muted">{helpText}</small>}
        </div>
    )
}