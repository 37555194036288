import React from 'react'

import Currency from 'react-currency-formatter';


export const CurrencyMX = props => {
    const { quantity } = props

    return (
        <div>
            <Currency
                quantity={quantity}
                currency="MXN"
                decimal="."
                group=","
                locale="es_CO"
            />
            {' '}
            MXN
        </div>
    )
}
