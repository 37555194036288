import React, { Component } from 'react';

import { AUX } from '../../hoc'


class dashboard extends Component {

    render() {
        return (
            <AUX>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        ¡Bienvenido!
                    </div>
                </div>
            </AUX>
        );
    }
}

export default dashboard;   