import React, { Component } from 'react'

import { Row, Col } from 'reactstrap';

import { AUX } from '../../hoc'
import { speakerService } from '../../_services'


export class SpeakerDetails extends Component {

    state = {
        title: '',
        full_name: '',
        email: '',
        phone: '',
        image: '',
        bio: ''
    }

    componentDidMount() {
        speakerService.retrieve(this.props.id)
            .then(response => {
                console.log(response)
                this.setState(response)
            })
    }

    render() {

        const { title, full_name, email, phone, image, bio } = this.state

        return (
            <AUX>
                <hr />
                <Row>
                    <Col>
                        <p>Ponente</p>
                        <p>{`${title} ${full_name}`}</p>
                    </Col>
                    <Col>
                        <p>{email}</p>
                        <p>{phone}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{bio}</p>
                    </Col>
                </Row>
                <hr />
            </AUX>
        );
    }
}
