import React from 'react'

import { Card, CardImg, CardBody, CardFooter, Button, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { LOCAL_DATE_TIME_FMT } from '../../../../_helpers'


const CardItem = props => {

    const { slug, title, banner, start, end, location, activities } = props.event

    return (
        <Card className="text-left">
            {banner && <CardImg top width="100%" src={banner} alt={title} />}
            <CardBody>
                <Link to={`/evento/${slug}`}><h5>{title} {end && moment(end) < moment(new Date()) && <Badge color='warning'>Evento finalizado</Badge>}</h5></Link>
                <p className="text-muted">{location}</p>
                {start && <p className="text-muted"><span className="fa fa-calendar"></span> {moment(start).format(LOCAL_DATE_TIME_FMT)}</p>}
                {/* {activities && activities.length && <p className="text-muted">Más de <strong>{activities.length}</strong> Conferencias y Talleres</p>} */}
            </CardBody>
            <CardFooter className="text-right">
                <Link to={`/evento/${slug}`}><Button block color="info">Ver más</Button></Link>
            </CardFooter>
        </Card>
    )
}

export default CardItem
