import { eventConstants } from '../_constants'
import { eventService } from '../_services'
import { alertActions } from './'

export const eventActions = {
    getAll,
    getAllPublished,
    create,
    retrieve,
    update,
    destroy,
    clear
}

function getAll() {
    return dispatch => {
        dispatch(request())

        eventService.getAll()
            .then(
                events => {
                    if (events) {
                        if (events.hasOwnProperty('results')) {
                            dispatch(success(events))
                        } else if (events.hasOwnProperty('errors')) {
                            dispatch(failure(events.errors))
                        } else {
                            dispatch(failure([{ message: events.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.GETALL_REQUEST } }
    function success(events) { return { type: eventConstants.GETALL_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GETALL_FAILURE, error } }
}

function getAllPublished() {
    return dispatch => {
        dispatch(request())

        eventService.getAllPublished()
            .then(
                events => {
                    if (events) {
                        if (events.hasOwnProperty('results')) {
                            dispatch(success(events))
                        } else if (events.hasOwnProperty('errors')) {
                            dispatch(failure(events.errors))
                        } else {
                            dispatch(failure([{ message: events.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.GETALL_REQUEST } }
    function success(events) { return { type: eventConstants.GETALL_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GETALL_FAILURE, error } }
}

function create(data) {
    return dispatch => {
        dispatch(request())

        eventService.create(data)
            .then(
                event => {
                    if (event && event.id) {
                        dispatch(success(event))
                        // history.push(`/admin/evento/${event.id}`)
                        // dispatch(alertActions.success("¡Evento creado correctamente!"))
                    } else if (event && event.errors) {
                        dispatch(failure(event.errors))
                        dispatch(alertActions.error(event.errors))
                    } else {
                        dispatch(failure([{ message: event.message }]))
                        dispatch(alertActions.error([{ message: event.message }]))
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.CREATE_REQUEST } }
    function success(event) { return { type: eventConstants.CREATE_SUCCESS, event } }
    function failure(error) { return { type: eventConstants.CREATE_FAILURE, error } }
}

function retrieve(slug) {
    return dispatch => {
        dispatch(request())

        eventService.retrieve(slug)
            .then(
                event => {
                    if (event) {                        
                        if (event.hasOwnProperty('slug')) {
                            dispatch(success(event))
                        } else if (event.hasOwnProperty('errors')) {
                            dispatch(failure(event.errors))
                        } else {
                            dispatch(failure([{ message: event.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.RETRIEVE_REQUEST } }
    function success(event) { return { type: eventConstants.RETRIEVE_SUCCESS, event } }
    function failure(error) { return { type: eventConstants.RETRIEVE_FAILURE, error } }
}

function update(id, data) {
    return dispatch => {
        dispatch(request())

        eventService.update(id, data)
            .then(
                event => {
                    if (event) {
                        if (event.hasOwnProperty('id')) {
                            dispatch(success(event))
                            dispatch(alertActions.updated("¡Evento actualizado correctamente!"))
                        } else if (event.hasOwnProperty('errors')) {
                            dispatch(failure(event.errors))
                            dispatch(alertActions.error(event.errors))
                        } else {
                            dispatch(failure([{ message: event.message }]))
                            dispatch(alertActions.error([{ message: event.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.UPDATE_REQUEST } }
    function success(event) { return { type: eventConstants.UPDATE_SUCCESS, event } }
    function failure(error) { return { type: eventConstants.UPDATE_FAILURE, error } }
}

function destroy(id) {
    return dispatch => {
        dispatch(request())

        const data = { status: 'deleted' }

        eventService.update(id, data)
            .then(
                event => {
                    if (event) {
                        if (event.hasOwnProperty('id')) {
                            dispatch(success(event))
                            dispatch(alertActions.success("¡Evento eliminado correctamente!"))
                        } else if (event.hasOwnProperty('errors')) {
                            dispatch(failure(event.errors))
                            dispatch(alertActions.error(event.errors))
                        } else {
                            dispatch(failure([{ message: event.message }]))
                            dispatch(alertActions.error([{ message: event.message }]))
                        }
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request() { return { type: eventConstants.UPDATE_REQUEST } }
    function success(event) { return { type: eventConstants.UPDATE_SUCCESS, event } }
    function failure(error) { return { type: eventConstants.UPDATE_FAILURE, error } }
}

function clear() {
    return { type: eventConstants.CURRENT_CLEAR }
}
