import { userConstants } from '../_constants'
import { userService } from '../_services'
import { alertActions } from './'
import { history } from '../_helpers'

export const userActions = {
    login,
    logout
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }))

        userService.login(email, password)
            .then(
                user => {
                    if (user && user.auth_token) {
                        dispatch(success(user))
                        history.push('/admin')
                    } else if (user && user.errors) {
                        dispatch(failure(user.errors))
                        dispatch(alertActions.error(user.errors))
                    } else {
                        dispatch(failure([{ message: user.message }]))
                        dispatch(alertActions.error([{ message: user.message }]))
                    }
                },
                error => {
                    console.error('error', error)
                }
            )
            .catch(error => console.error(error))
    }

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout()
    return { type: userConstants.LOGOUT }
}
