import React, { Component } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import SweetAlert from 'react-bootstrap-sweetalert';

import { AUX } from '../../hoc'
import { eventActions } from '../../_actions';


export class EditEvent extends Component {

    state = {
        submitted: false,
        event: {}
    }

    componentDidMount() {
        const { params } = this.props.match
        const { dispatch } = this.props

        if (params.hasOwnProperty('id')) {
            dispatch(eventActions.retrieve(params.id))
        }
        this.setState({ submitted: true })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.current_event.hasOwnProperty('id')) {
            this.setState({ event: nextProps.current_event })
        }
    }

    handleDefault = date => this.setState({ default_date: date })
    handleAutoClose = date => this.setState({ auto_close: date })

    handleStart = date => {
        const { event } = this.state
        event['start'] = date
        this.setState({ event })
    }

    handleEnd = date => {
        const { event } = this.state
        event['end'] = date
        this.setState({ event })
    }

    handleChange = e => {
        const { name, value } = e.target
        const event = this.state.event
        event[name] = value
        this.setState({ event })
    }

    handleSubmit = e => {
        e.preventDefault()

        this.setState({ submitted: true })
        const { dispatch } = this.props
        const { id } = this.state.event
        console.log(id);

        dispatch(eventActions.update(id, this.state.event))
    }

    render() {
        const { submitted, event } = this.state
        const { alert } = this.props
        const { title, description, capacity, price, status, start, end } = event

        return (
            <AUX className="waves-effect waves-light">
                <div className="page-content-wrapper">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Actualizar Evento</h3>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12">
                                                {alert && typeof alert.message === 'string' &&
                                                    <div className={`alert ${alert.type} alert-dismissible fade show`} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                        {alert.message}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <form className={"form-horizontal needs-validation" + (submitted ? " was-validated" : "")} onSubmit={this.handleSubmit} noValidate>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="title" className="col-lg-2 col-form-label">Título</label>
                                                        <div className="col-lg-10">
                                                            <input required name="title" value={title} onChange={this.handleChange} type="text" className="form-control" />
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'title' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="description" className="col-lg-2 col-form-label">Descripción</label>
                                                        <div className="col-lg-10">
                                                            <textarea name="description" onChange={this.handleChange} className="form-control" value={description || ""} />
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'description' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="capacity" className="col-lg-3 col-form-label">Capacidad</label>
                                                        <div className="col-lg-9">
                                                            <input name="capacity" value={capacity} onChange={this.handleChange} type="number" step="1" min="0" className="form-control" />
                                                            <small className="form-text text-muted">0 para ilimitado</small>
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'capacity' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="price" className="col-lg-3 col-form-label">Precio</label>
                                                        <div className="col-lg-9">
                                                            <input name="price" value={price} onChange={this.handleChange} type="number" min="0" className="form-control" />
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'price' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="status" className="col-lg-3 col-form-label">Status</label>
                                                        <div className="col-lg-9">
                                                            <select name="status" value={status} onChange={this.handleChange} className="form-control">
                                                                <option value="draft">Borrador</option>
                                                                <option value="published">Publicado</option>
                                                            </select>
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'status' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />

                                            <h5 className="m-t-40">Fechas del evento</h5>
                                            <br />
                                            <div className="row m-b-30">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="start" className="col-lg-3 col-form-label">Inicia</label>
                                                        <div className="col-lg-9">
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={start && new Date(start)}
                                                                onChange={this.handleStart}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeCaption="Hora"
                                                                dateFormat="dd/MMM/yyyy h:mm aa"
                                                            />
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'start' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="end" className="col-lg-3 col-form-label">Termina</label>
                                                        <div className="col-lg-9">
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={start && new Date(end)}
                                                                onChange={this.handleEnd}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeCaption="Hora"
                                                                dateFormat="dd/MMM/yyyy h:mm aa"
                                                            />
                                                            {alert && alert.type === 'alert-danger' &&
                                                                <div className="text-danger">
                                                                    {alert.message.map((item, key) => item.field === 'end' ? <span key={key}>{item.message}</span> : "")}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-t-40">
                                                <div className="col-lg-12 text-right">
                                                    <Button color="info">Actualizar</Button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>


            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    const { alert, authentication, current_event } = state

    return {
        alert,
        user: authentication.user,
        current_event,
    }
}

EditEvent = withRouter(connect(mapStatetoProps)(EditEvent))
