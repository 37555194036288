const validate = values => {
    const errors = {}
    if (!values.title) {
        errors.title = 'El título es obligatorio'
    }
    if (!values.start) {
        errors.start = 'La fecha de Inicio es obligatoria'
    }
    if (!values.end) {
        errors.end = 'La fecha de Fin es obligatoria'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    
    if (!values.talks || !values.talks.length) {
        errors.talks = { _error: 'Se debe crear al menos una ponencia.' }
    } else {
        const talksArrayErrors = []
        values.talks.forEach((talk, talkIndex) => {
            const talkErrors = {}
            if (!talk || !talk.title) {
                talkErrors.title = 'El título es obligatorio'
                talksArrayErrors[talkIndex] = talkErrors
            }
            if (!talk || !talk.start) {
                talkErrors.start = 'La fecha de inicio es obligatoria'
                talksArrayErrors[talkIndex] = talkErrors
            }
        })
        if (talksArrayErrors.length) {
            errors.talks = talksArrayErrors
        }
    }

    return errors
}

export default validate;