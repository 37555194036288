import React from 'react'

import { Row, Col } from 'reactstrap'

import Speaker from './Speaker'
import { CurrencyMX } from '../../../UI/Currency'


const Talk = props => {
    const { title, price, capacity, speakers, speaker_types } = props
    return (
        <div>
            <hr />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <div>
                                <strong>{title}</strong>
                                <br />
                                {speakers && speakers.map((speaker, key) => <Speaker key={key} {...speaker} speaker_types={speaker_types} />)}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><i className="text-success fa fa-money"></i> {parseFloat(price) === 0 ? 'Actividad gratuita' : <CurrencyMX quantity={parseFloat(price)} />}</p>
                            <p><i className="text-info fa fa-users"></i> Capacidad: {parseFloat(capacity) === 0 ? 'Ilimitada' : capacity}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Talk
