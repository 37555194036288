import React from 'react'

import { Row, Col } from 'reactstrap'

const Speaker = props => {

    const { title, speaker_types, first_name, last_name } = props

    let currentSpeakerType = speaker_types.find(type => type.id === title)
    currentSpeakerType = currentSpeakerType ? currentSpeakerType.abbreviation : ""

    const full_name = `${currentSpeakerType} ${first_name} ${last_name}`
    return (
        <small>
            {full_name}
            <br/>
        </small>
    )
}

export default Speaker
