export const talkConstants = {
    GETALL_REQUEST: 'TALKS_GETALL_REQUEST',
    GETALL_SUCCESS: 'TALKS_GETALL_SUCCESS',
    GETALL_FAILURE: 'TALKS_GETALL_FAILURE',

    CREATE_REQUEST: 'TALK_CREATE_REQUEST',
    CREATE_SUCCESS: 'TALK_CREATE_SUCCESS',
    CREATE_FAILURE: 'TALK_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'TALK_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'TALK_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'TALK_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'TALK_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TALK_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TALK_UPDATE_FAILURE',

    CURRENT_CLEAR: 'TALK_CURRENT_CLEAR',

}
