import React from 'react'


const footer = (props) => (
    <footer className="footer">
        ©   {new Date().getFullYear() - 1} -   {new Date().getFullYear()} Kodeference <span className="text-muted d-none d-sm-inline-block float-right">Powered by <a target="_blank" rel="noopener noreferrer" href="https://emkode.com">Emkode</a></span>
    </footer>
);

export default footer;
