export const speakerTypeConstants = {
    GETALL_REQUEST: 'SPEAKER_TYPES_GETALL_REQUEST',
    GETALL_SUCCESS: 'SPEAKER_TYPES_GETALL_SUCCESS',
    GETALL_FAILURE: 'SPEAKER_TYPES_GETALL_FAILURE',

    CREATE_REQUEST: 'SPEAKER_TYPE_CREATE_REQUEST',
    CREATE_SUCCESS: 'SPEAKER_TYPE_CREATE_SUCCESS',
    CREATE_FAILURE: 'SPEAKER_TYPE_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'SPEAKER_TYPE_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'SPEAKER_TYPE_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'SPEAKER_TYPE_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'SPEAKER_TYPE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SPEAKER_TYPE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SPEAKER_TYPE_UPDATE_FAILURE',

    CURRENT_CLEAR: 'SPEAKER_TYPE_CURRENT_CLEAR',

}
