export const orderConstants = {
    GETALL_REQUEST: 'ORDERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORDERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORDERS_GETALL_FAILURE',

    CREATE_REQUEST: 'ORDER_CREATE_REQUEST',
    CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
    CREATE_FAILURE: 'ORDER_CREATE_FAILURE',

    RETRIEVE_REQUEST: 'ORDER_RETRIEVE_REQUEST',
    RETRIEVE_SUCCESS: 'ORDER_RETRIEVE_SUCCESS',
    RETRIEVE_FAILURE: 'ORDER_RETRIEVE_FAILURE',

    UPDATE_REQUEST: 'ORDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORDER_UPDATE_FAILURE',

    CURRENT_CLEAR: 'ORDER_CURRENT_CLEAR',

}
