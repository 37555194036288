import React from 'react'

const RenderField = ({ input, label, type, helpText, placeholder, rows, required, min, step, style, options, meta: { touched, error } }) => {

    switch (type) {
        case "textarea":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <textarea {...input} className="form-control" required={required} rows={rows} placeholder={placeholder} style={style} />
                    <small className="form-text text-muted">{helpText}</small>
                    {touched && error && <div className="invalid-feedback">{error}</div>}
                </div>
            )
        case "number":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <input {...input} type={type} className="form-control" min={min} step={step} required={required} />
                    <small className="form-text text-muted">{helpText}</small>
                    {touched && error && <div className="invalid-feedback">{error}</div>}
                </div>
            )
        case "select":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <select {...input} className="form-control" required={required}>
                        {options.map((item, key) => <option key={key} value="item.key">{item.value}</option>)}
                    </select>
                    <small className="form-text text-muted">{helpText}</small>
                    {touched && error && <div className="invalid-feedback">{error}</div>}
                </div>
            )

        default:
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <input {...input} type={type} className="form-control" required={required} />
                    <small className="form-text text-muted">{helpText}</small>
                    {touched && error && <div className="invalid-feedback">{error}</div>}
                </div>
            )
    }

}
export default RenderField