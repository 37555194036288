import { combineReducers } from 'redux'

import { reducer as reduxFormReducer } from 'redux-form';

import { alert } from './alert.reducer'
import { authentication } from './authentication.reducer'
import { theme } from './theme.reducer'
import { events, current_event } from './event.reducer'
import { speakers, current_speaker } from './speaker.reducer'
import { talks } from './talk.reducer'
import { orders, current_order } from './order.reducer'
import { guests, current_guest } from './guest.reducer'
import { speaker_types } from './speakerType.reducer'



const rootReducer = combineReducers({
    authentication,
    alert,
    ui_red: theme,
    form: reduxFormReducer,
    events,
    current_event,
    speakers,
    current_speaker,
    talks,
    orders,
    current_order,
    guests,
    current_guest,
    speaker_types,
})

export default rootReducer
