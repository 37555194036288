import { API } from '../_utils'


const login = (email, password) => {
    const params = {
        email: email,
        password: password
    }

    return API.post(`/auth/login`, params)
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response.data))
            return response.data
        })
        .catch(error => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                return error.response.data
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
            return error
        })
}

const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
}


export const userService = {
    login,
    logout
}