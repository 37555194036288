import React from 'react'

import { Button, Row, Col, Label } from 'reactstrap'

import { WorkShops } from './WorkShops'
import { RenderField } from '../../UI/Input'
import { DatePickerBirthday } from '../../UI/DateTimePicker'


export const Form = props => {
    const { submitted, onSubmit, onChange, onChangeBirthDay, data, workshops, onChangeWorkShop, errors, onChangeFile } = props
    return (
        <form className={"form-horizontal needs-validation" + (submitted ? " was-validated" : "")} onSubmit={onSubmit} noValidate>
            <Row>
                <Col md={6} className="mb-3">
                    <RenderField
                        name="first_name"
                        type="text"
                        required
                        label="Nombre(s)"
                        onChange={onChange}
                        error={errors.find(item => item.field === "first_name")}
                    />
                </Col>
                <Col md={6} className="mb-3">
                    <RenderField
                        name="last_name"
                        type="text"
                        required
                        label="Apellidos"
                        onChange={onChange}
                        error={errors.find(item => item.field === "last_name")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <RenderField
                        name="email"
                        type="email"
                        required
                        label="Correo"
                        onChange={onChange}
                        error={errors.find(item => item.field === "email")}
                    />
                </Col>
                <Col md={6} className="mb-3">
                    <DatePickerBirthday
                        name="birthday"
                        value={data.birthday}
                        onChange={onChangeBirthDay}
                        label={"Fecha de nacimiento"}
                        error={errors.find(item => item.field === "birthday")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <RenderField
                        name="origin_place"
                        type="text"
                        label="Lugar de Residencia"
                        onChange={onChange}
                        error={errors.find(item => item.field === "origin_place")}
                    />
                </Col>

                <Col md={6} className="mb-3">
                    <RenderField
                        name="institution"
                        type="text"
                        label="Institución de Procedencia"
                        onChange={onChange}
                        error={errors.find(item => item.field === "institution")}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={6} className="mb-3">
                    <Label>Ocupación <span className='text-danger'>*</span></Label>
                    <select name="guest_type" className="custom-select d-block w-100" defaultValue="" required onChange={onChange}>
                        <option value=""></option>
                        <option value="professional">Profesionista</option>
                        <option value="student">Estudiante</option>
                        <option value="other">Otro</option>
                    </select>
                    <div className="invalid-feedback">
                        El campo es obligatorio.
                    </div>
                    {/*{data.guest_type && data.guest_type === 'student' &&
                        <div className="custom-file m-t-20">
                            <input required type="file" className="custom-file-input" id="validatedCustomFile" name='document' onChange={onChangeFile} />
                            <label className="custom-file-label" htmlFor="validatedCustomFile" data-browse="Elegir">Credencial estudiante.</label>
                            <div className="invalid-feedback">Archivo Inválido</div>
                            <span className="text-muted">Sube un documento que avale que eres estudiante para obtener un descuento.</span>
                        </div>
                    }*/}
                </Col>

                <Col md={6} className="mb-3">
                    <RenderField
                        name="phone"
                        type="text"
                        label="Teléfono"
                        onChange={onChange}
                        error={errors.find(item => item.field === "phone")}
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12} className="mb-3">
                    <h5>Selección de talleres con costo</h5>
                    <WorkShops items={workshops} onChange={onChangeWorkShop} />
                </Col>
            </Row>
            <hr className="mb-4" />
            <Button className="btn btn-primary btn-lg btn-block" type="submit">
                {/* <span className="fa fa-paypal"></span>{' '}
                Pago Paypal */}
                Continuar{' '}
                <span className="fa fa-arrow-circle-right"></span>
            </Button>
        </form>
    )
}
