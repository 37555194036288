import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button } from 'reactstrap'

import RenderField from './RenderField'
import { DateTimePicker } from '../../UI/DateTimePicker'


class RenderTalk extends Component {

    state = {
        speaker: {},
        modalNewSpeaker: false
    }

    render() {
        const { fields, meta, toggleModalSpeaker, speakers, current_speaker } = this.props
        const { touched, error, submitFailed } = meta

        return (
            <div>
                {(touched || submitFailed) && error &&
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {error}
                    </div>
                }

                <Button
                    color="success"
                    className="btn-sm"
                    onClick={() => fields.push({})}
                >
                    <i className="fa fa-plus"></i> Nueva
                </Button>



                <div id="accordion" className="m-t-20">
                    {fields.map((talk, index) => (
                        <div className="card" key={index}>
                            <div className="card-header p-2" id={`heading${index}`}>
                                <p className="m-0">
                                    <a
                                        href={`#collapse${index}`}
                                        className="text-dark"
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                        aria-controls={`collapse${index}`}
                                    >
                                        <strong>Ponencia #{index + 1}</strong>
                                    </a>

                                    <Button
                                        color="danger"
                                        className="btn-sm pull-right"
                                        onClick={() => fields.remove(index)}
                                    >
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                </p>
                            </div>

                            <div id={`collapse${index}`} className="collapse show" aria-labelledby={`heading${index}`} data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <Field
                                                name={`${talk}.title`}
                                                type="text"
                                                component={RenderField}
                                                label="Título"
                                                required
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Status</label>
                                            <Field
                                                name={`${talk}.status`}
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="draft">Borrador</option>
                                                <option value="approved">Aprobada</option>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Tipo</label>
                                            <Field
                                                name={`${talk}.talk_type`}
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="talk">Conferencia</option>
                                                <option value="workshop">Taller</option>
                                            </Field>
                                        </div>
                                        <div className="col-md-4">
                                            <Field
                                                name={`${talk}.capacity`}
                                                type="number"
                                                component={RenderField}
                                                step="1"
                                                min="0"
                                                label="Capacidad"
                                                helpText="Capacidad de personas, 0 para ilimitado"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Field
                                                name={`${talk}.price`}
                                                type="number"
                                                component={RenderField}
                                                label="Precio"
                                            />
                                        </div>
                                    </div>

                                    <div className="row m-b-10">
                                        <div className="col-md-6">
                                            <label>Ponente</label>
                                            <div className="input-group">
                                                <Field
                                                    name={`${talk}.speaker`}
                                                    component="select"
                                                    className="form-control"
                                                >
                                                    <option></option>
                                                    {speakers.loading && <option>Cargando...</option>}
                                                    {speakers.results &&
                                                        speakers.results.map((speaker, key) => <option key={key} value={speaker.id}><span className="text-uppercase">{speaker.title}</span>{`${speaker.first_name} ${speaker.last_name}`}</option>)
                                                    }
                                                </Field>
                                                <div className="input-group-append">
                                                    <Button
                                                        color="success"
                                                        className="btn-sm waves-effect waves-light"
                                                        type="button"
                                                        onClick={toggleModalSpeaker}
                                                    >
                                                        <i className="fa fa-plus"></i> Nuevo ponente
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name={`${talk}.start`} component={DateTimePicker} required label="Fecha de inicio" />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name={`${talk}.duration`}
                                                component={RenderField}
                                                type="number"
                                                min="0"
                                                label="Duración"
                                                helpText="Duración en minutos"
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                name={`${talk}.abstract`}
                                                type="textarea"
                                                component={RenderField}
                                                label="Resumen"
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                name={`${talk}.observations`}
                                                type="textarea"
                                                component={RenderField}
                                                label="Observaciones"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

}

const mapStatetoProps = state => {
    const { alert, speakers, current_speaker } = state
    return {
        alert,
        speakers,
        current_speaker
    }
}

export default withRouter(connect(mapStatetoProps)(RenderTalk))