import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import { Container, Row, Col, Card, CardBody, Button, Badge } from 'reactstrap'

import { DATE_FMT } from '../../../_helpers'
import { eventActions, orderActions, speakerActions, speakerTypeActions } from '../../../_actions'
import { DataTableCertificate } from '../../../../src/components/Event/Certificate'
import { API_URL } from '../../../../src/_utils/API'

export class Constancia extends Component {

    state = {
        total: 0,
        discount: 0,
        purchased_workshops: [],
        guest: {},
        errors: [],
        submitted: false,
        validate: false,
        firstNameSearched: "",
        lastNameSearched: "",
        guests: [],
        talk: "",
        searching: false,
        selectedActivity:"",
        activities: [],

    }

    componentDidMount() {
        const { getCurrentEvent, getAllSpeakers, getAllSpeakersTypes,  } = this.props
        const { slug } = this.props.match.params
        getCurrentEvent(slug)
        getAllSpeakers()
        getAllSpeakersTypes()
        //This a API GET petition. We use it to get the talks and display them into the select filter.
        fetch(`${API_URL}/talks`)
            .then(response => response.json())
            .then(results =>
                this.setState({activities: results.results}))
            .catch(e => {
                console.log(e);
                this.setState({...this.state});
            });

    }

    componentWillReceiveProps(nextProps) {
        const { event, alert } = nextProps

        if (alert && alert.hasOwnProperty('message')) {
            console.log(alert)
            window.scrollTo(0, 0)
        }


        if (event && event.activities) {
            const purchased_workshops = []

            for (const activity of event.activities) {
                for (const talk of activity.talks) {
                    const capacity = parseInt(talk.capacity)
                    const price = parseFloat(talk.price)
                    const registered = parseFloat(talk.registered)
                    if (price === 0 && ((capacity > 100 && capacity > registered) || capacity === 0)) {
                        purchased_workshops.push(talk)
                    }
                }
            }
            this.setState({ total: parseFloat(event.price), purchased_workshops })
        }
    }

    handleOnChange = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.value
        this.setState({ guest })
    }

    handleOnChangeGuestType = e => {
        let { guest, discount } = this.state
        const guest_type = e.target.value
        guest[e.target.name] = guest_type

        if (guest_type === 'student') {
            const { event } = this.props
            discount = event.price
        } else {
            discount = 0
            delete guest['document']
        }

        this.setState({ guest, discount })
    }

    handleOnChangeFile = e => {
        let { guest } = this.state
        guest[e.target.name] = e.target.files[0]
        this.setState({ guest })
    }

    handleOnChangeTalk = e => {
        const { event } = this.props
        let { purchased_workshops, total } = this.state
        let indexTalk = purchased_workshops.findIndex(item => item.id === e.target.name)

        const talks = []
        event.activities.map(activity => activity.talks.map(talk => talks.push(talk)))
        const talk_detail = talks.find(talk => talk.id === e.target.name)


        if (indexTalk > -1) {
            purchased_workshops.splice(indexTalk, 1)
            total -= parseFloat(talk_detail.price)
        } else {
            purchased_workshops.push(talk_detail)
            total += parseFloat(talk_detail.price)
        }
        this.setState({ purchased_workshops, total })
    }

    handleOnChangeBirthday = date => {
        let { guest } = this.state
        guest['birthday'] = date ? date : null
        this.setState({ guest })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({ submitted: true })

        const { purchased_workshops, guest, discount } = this.state
        const { createOrder, event } = this.props
        const { first_name, last_name, email, guest_type, document, birthday } = guest

        if (first_name && last_name && email && guest_type) {
            if ((guest_type === 'student' && document) || guest_type !== 'student') {
                this.setState({ validate: true })
                let formData = new FormData()
                guest['event'] = event.slug
                guest['discount'] = discount
                for (var key in guest) {
                    formData.append(`guest.${key}`, guest[key])
                }
                formData.set('guest.birthday', moment(birthday).format(DATE_FMT))
                purchased_workshops.map(item => formData.append('talks', item.id))
                createOrder(formData)
            }
        }
    }

    handleSubmitMail(first_name, last_name, email, url){
        fetch(`${API_URL}/send-mail?first_name=${first_name}&last_name=${last_name}&email=${email}&url=${url}`)
            .then(res => res.json())
        alert('Correo enviado a ' + first_name + ' ' + last_name)
    }

    _handleChangeFirstName = (e) => {
        this.setState({ firstNameSearched: e.target.value })
    }
    _handleChangeLastName = (e) => {
        this.setState({ lastNameSearched: e.target.value })
    }
    _handleChangeActivity = (e) => {
        this.setState({ selectedActivity: e.target.value })
    }
    _handleSubmit = (e) => {
        e.preventDefault()
        const { firstNameSearched } = this.state
        const { lastNameSearched } = this.state
        const { selectedActivity } = this.state
        this.setState({ searching: true })
        fetch(`${API_URL}/guest-search?first_name=${firstNameSearched}&last_name=${lastNameSearched}&talk=${selectedActivity}`)
            .then(res => res.json())
            .then(results => {
                this.setState({ guests: results.results, searching: false })
            })
            .catch(() => this.setState({ searching: false }))
    }

    render() {
        const { purchased_workshops, total, submitted, guest, discount, validate, searching, activities} = this.state
        const { event, alert, speakers, speaker_types } = this.props
        const columns = [
            {
                label: 'Nombre',
                field: 'first_name',
                sort: 'asc',
            }, {
                label: 'Apellido',
                field: 'last_name'
            },{
                label: 'PDF',
                field: 'pdf'
            }
        ]

        var mostrarUsuarios = activities.map(activity => {
            let id = activity.id
            let title = activity.title
            return (<option value={id}>{title}</option>);
        });

        return (
            <div>
                {event && event.loading &&
                    <div>Cargando...</div>
                }
                {event && event.slug &&
                    <Container>
                        <br /><br /><br />
                        <Card className="mb-4">
                            <CardBody>
                                <Row>
                                    <Col>
                                        <form onSubmit={this._handleSubmit}>
                                            <Row>
                                                <Col>
                                                    <input
                                                        onChange={this._handleChangeFirstName}
                                                        type="text"
                                                        placeholder="Nombre"
                                                        className="form-control"
                                                    />
                                                </Col>
                                                <Col>
                                                    <input
                                                        onChange={this._handleChangeLastName}
                                                        type="text"
                                                        placeholder="Apellidos"
                                                        className="form-control"
                                                    />
                                                </Col>
                                                <Col>
                                                    <select name="guest_type" className="custom-select d-block w-100" defaultValue="" onChange={this._handleChangeActivity} required>
                                                    <option value="">Actividad</option>
                                                    {mostrarUsuarios}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3">
                                                <Col>
                                                    {!searching &&
                                                        <Button className="btn-block" color="info">
                                                            <span className="fa fa-search" /> Buscar
                                                        </Button>
                                                    }
                                                    {searching &&
                                                        <Button className="btn-block" color="info" disabled>
                                                            <span className="fas fa fa-spinner fa-spin" /> Buscando
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col>
                                        <DataTableCertificate
                                            columns={columns}
                                            data={this.state.guests}
                                            onDelete={this.handleDelete}
                                            onSubmitMail={this.handleSubmitMail}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Container>

                }
            </div>
        )
    }
}

const mapStatetoProps = state => {
    const { alert, current_event, speakers, speaker_types } = state
    return {
        alert,
        event: current_event,
        speakers,
        speaker_types
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        getCurrentEvent: slug => dispatch(eventActions.retrieve(slug)),
        createOrder: payload => dispatch(orderActions.create(payload)),
        getAllSpeakers: () => dispatch(speakerActions.getAll()),
        getAllSpeakersTypes: () => dispatch(speakerTypeActions.getAll())
    }
}

Constancia = withRouter(connect(mapStatetoProps, mapDispatchToProps)(Constancia))
