import React from 'react'

import { Row, Col, Container, Jumbotron } from 'reactstrap'

import { AUX } from '../../../../hoc'
import CardItem from './CardItem'


export const ListEvents = props => {
    const { events } = props
    return (
        <AUX>
            <Container>
                <Row>
                    <Col className="py-1 text-center">
                        <img className="d-block mx-auto mb-4" src="/assets/images/emkode.png" height="120" alt="kodeference" />
                    </Col>
                </Row>
            </Container>
            {/* <Jumbotron className="text-center">
                <h3>Próximos eventos</h3>
            </Jumbotron> */}
            <Container color='white'>
                <Row>
                    {events && events.map((event, key) =>
                        <Col className="py-1 text-center" md={4} key={key}>
                            <CardItem event={event} />
                        </Col>
                    )}
                </Row>
            </Container>
            <footer className="my-5 pt-5 text-muted text-center text-small">
                <p className="mb-1">&copy; 2018-2021 Kodeference</p>
                <ul className="list-inline">
                    <li className="list-inline-item">Powered by <a href="https://emkode.com" target="_blank" rel="noopener noreferrer">Emkode</a></li>
                </ul>
            </footer>
        </AUX>
    )
}
