import React from 'react'

import moment from 'moment'

import { DATE_FMT } from '../../../_helpers'


export const RenderField = ({ name, onChange, label, type, helpText, placeholder, rows, required, min, max, step, style, options, error }) => {

    switch (type) {
        case "textarea":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <textarea name={name} className="form-control" required={required} rows={rows} placeholder={placeholder} style={style} onChange={onChange} />
                    {helpText && <small className="form-text text-muted">{helpText}</small>}
                    {error && <small className="text-danger">{error.message}</small>}
                    <div className="invalid-feedback">
                        {required && "El campo es obligatorio."}
                    </div>
                </div>
            )
        case "number":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <input name={name} type={type} className="form-control" min={min} step={step} required={required} onChange={onChange} />
                    {helpText && <small className="form-text text-muted">{helpText}</small>}
                    {error && <small className="text-danger">{error.message}</small>}
                    <div className="invalid-feedback">
                        {required && "El campo es obligatorio."}
                    </div>
                </div>
            )
        case "date":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <input name={name} type={type} className="form-control" min={moment(min).format(DATE_FMT)} max={moment(max).format(DATE_FMT)} step={step} required={required} onChange={onChange} />
                    {helpText && <small className="form-text text-muted">{helpText}</small>}
                    {error && <small className="text-danger">{error.message}</small>}
                    <div className="invalid-feedback">
                        {required && "El campo es obligatorio."}
                    </div>
                </div>
            )
        case "select":
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <select name={name} className="form-control" required={required} onChange={onChange}>
                        {options.map((item, key) => <option key={key} value="item.key">{item.value}</option>)}
                    </select>
                    {error && <small className="text-danger">{error.message}</small>}
                    {helpText && <small className="form-text text-muted">{helpText}</small>}
                    <div className="invalid-feedback">
                        {required && "El campo es obligatorio."}
                    </div>
                </div>
            )

        default:
            return (
                <div className="form-group">
                    <label>{label}{required && <span className="text-danger"> *</span>}</label>
                    <input name={name} type={type} className="form-control" required={required} onChange={onChange} />
                    {error && <small className="text-danger">{error.message}</small>}
                    {helpText && <small className="form-text text-muted">{helpText}</small>}
                    <div className="invalid-feedback">
                        {required && "El campo es obligatorio."}
                    </div>
                </div>
            )
    }

}